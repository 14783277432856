enum AccountStatusEnum {
  REFERRED_OUT = 'referred_out',
  NEEDS_PRESCRIBER_REVIEW = 'needs_prescriber_review',
  CANCELED = 'canceled',
  CANCELLATION_SCHEDULED = 'cancellation_scheduled',
  SUSPENDED = 'suspended',
  PAUSED = 'paused',
  PAID_BUT_INCOMPLETE = 'paid_but_incomplete',
  PAUSE_SCHEDULED = 'pause_scheduled',
  ACTIVE_CLIENT = 'active_client',
  ACTIVE_CLIENT_WITHOUT_PROVIDER = 'active_client_without_provider',
  UNASSIGNED = 'unassigned',
  LEAD = 'lead',
  TRIAL = 'trial',
  INELIGIBLE = 'ineligible',
  UNCLASSIFIABLE = 'unclassifiable'
}

export default AccountStatusEnum;

export enum ReassignMode {
  SELF_SERVE = 'ss'
}

export enum RTEStatusEnum {
  PENDING = 'pending',
  ERROR = 'error',
  ELIGIBLE = 'eligible',
  INELIGIBLE = 'ineligible',
  PRE_RTE = 'pre-rte',
  VIEWED_SERVICE_SELECTION = 'viewed_service_selection'
}
