import axios from 'axios';
import { setCustomDataKameleoon } from 'utils/kameleoon';
import segment from '../utils/segment';
import tracking from '../utils/tracking';
// import { batch } from 'react-redux';

export const RESET_STATE = 'RESET';
export const SIGN_IN = 'SIGN_IN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const REGISTER = 'REGISTER';
export const SET_USER = 'SET_USER';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_PATIENT = 'SET_USER_PATIENT';
export const SET_THERAPIST = 'SET_USER_THERAPIST';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SAVE_URL = 'SAVE_URL';
export const SET_PHONE = 'SET_PHONE';

// set_user will update global state with information corresponding to the User object in the database
export const set_user = user_info => ({
  type: SET_USER,
  user_attr: user_info
});

export const update_user_info = user_info => ({
  type: UPDATE_USER_INFO,
  user_info
});

export const remove_token = () => ({
  type: REMOVE_TOKEN
});

export const sign_out = () => dispatch => {
  setCustomDataKameleoon('isLoggedIn', false);
  setCustomDataKameleoon('userId', null);
  return dispatch({ type: RESET_STATE });
};

export const reset_state = () => dispatch => {
  return dispatch({ type: RESET_STATE });
};

export const get_user_attr = state => {
  return state.global_reducer?.current_user?.attributes;
};

export const setPhone = ({ phone }) => ({
  type: SET_PHONE,
  phone
});

export const make_headers = user_attr => {
  const headers = {
    'Content-Type': 'application/json',
    'access-token': user_attr['access-token'],
    client: user_attr.client,
    uid: user_attr.uid
  };

  if (user_attr['jwt-token']) {
    headers.Authorization = `Bearer ${user_attr['jwt-token']}`;
  }

  return headers;
};

export const update_password_api = (axiosApi, new_pwd, new_pwd_confirm) => (dispatch, getState) => {
  const user = get_user_attr(getState());
  return axiosApi.put(`/api/v3/auth/patients/${user.patient.id}/change_password`, {
    password: new_pwd,
    password_confirmation: new_pwd_confirm
  });
};

export const is_signed_in = api => {
  return api
    .get('/api/auth/validate_token')
    .then(resp => {
      const {
        headers: { expiry }
      } = resp;
      const dayInMiliseconds = 86400000;

      if (expiry && Number(expiry) * 1000 - Date.now() >= dayInMiliseconds) {
        setCustomDataKameleoon('isLoggedIn', true);
        setCustomDataKameleoon('userId', resp.data.data.id);
        return Promise.resolve(true);
      }
      setCustomDataKameleoon('isLoggedIn', false);
      setCustomDataKameleoon('userId', null);
      return Promise.resolve(false);
    })
    .catch(() => {
      setCustomDataKameleoon('isLoggedIn', false);
      setCustomDataKameleoon('userId', null);
      return Promise.resolve(false);
    });
};

export const signInByAccessToken = (axiosApi, accessToken) => dispatch => {
  if (!accessToken) return Promise.reject(new Error("Can't sign in without access token"));
  const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` };
  return (axios || axiosApi)
    .post('/api/v3/auth/authenticate_user', null, { headers })
    .then(resp => {
      resp.data.data.client = resp.headers.client;
      resp.data.data['access-token'] = resp.headers['access-token'];
      resp.data.data['jwt-token'] = resp.headers['jwt-token'];
      dispatch(set_user(resp.data.data));
      return resp.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const authenticateFromMobile = user => dispatch => {
  try {
    const userData = JSON.parse(user);
    if (userData.email && userData.id) {
      segment.identify(userData.id, userData.email);
      window.dataLayer?.push({ user_id: String(userData.id) });
      setCustomDataKameleoon('userId', userData.id);
      setCustomDataKameleoon('isLoggedIn', true);
    }
    dispatch(set_user(userData));
  } catch (e) {
    // This is a fallback for when the user string it is a invalid JSON, this will be monitored and moved to the try above
    try {
      const userData = JSON.parse(user.replaceAll('\n', '\\n').replaceAll('\r', '\\r'));
      dispatch(set_user(userData));
      tracking.infoV2('Recovered user from mobile with invalid JSON');
    } catch (err) {
      tracking.error({ e, message: 'User String was not valid JSON', info: user });
    }
  }
};

export const update_user_anonymous_id = (axiosApi, email, anonymous_id) => dispatch => {
  const params = {
    email,
    anonymous_id
  };

  return axiosApi.patch('/api/anonymous_appointment/update_user', params).then(resp => {
    if (resp.status === 200) {
      dispatch(update_user_info({ anonymous_appointment_id: anonymous_id }));
    } else {
      tracking.error({ e: resp.error, message: resp.message, info: email });
    }

    return resp;
  });
};

// eslint-disable-next-line consistent-return
export const update_user_information = (axiosApi, user_info) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axiosApi.put(`/api/users/${user_attr.id}`, user_info).then(resp => {
    const { id, email } = resp.data;
    segment.identify(id, email);
    window.dataLayer?.push({ user_id: String(id) });
    dispatch(update_user_info(user_info));
    return resp;
  });
};

export const sign_in = user_info => dispatch => {
  const { email, password, token } = user_info;

  // sign in API call here
  const headers = { 'Content-Type': 'application/json' };
  let payload;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (email && password) {
    payload = { email, password };
  }

  if (payload || token) {
    return axios
      .post('/api/auth/sign_in', payload, { headers })
      .then(resp => {
        resp.data.data.client = resp.headers?.client;
        resp.data.data['access-token'] = resp.headers['access-token'];
        resp.data.data['jwt-token'] = resp.headers['jwt-token'];

        dispatch(set_user(resp.data.data));
        segment.identify(resp.data.data.id, email);
        setCustomDataKameleoon('userId', resp.data.data.id);
        window.dataLayer?.push({ user_id: String(resp.data.data.id) });
        setCustomDataKameleoon('isLoggedIn', true);
        return resp.data;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  return Promise.reject(new Error('Please provide an email address and password'));
};

export const register_user = user_info => () => {
  const {
    sms_opted_in,
    receive_content_notifications,
    consents_to_sms_marketing,
    first_name,
    last_name,
    email,
    password,
    password_confirm,
    phone_number,
    phone_region,
    skip_password_validation,
    acquisition_string,
    feature_flag,
    service_line,
    country,
    is_guest = false
  } = user_info;

  // NOTE: you can pass in both skip_password_validation and password+password_confirm but the server will basically ignore the password

  // register API call here
  //
  const headers = { 'Content-Type': 'application/json' };
  let user_data = {};
  if (first_name && last_name) {
    user_data = { first_name, last_name };
  }
  if (email && ((password && password_confirm) || skip_password_validation)) {
    user_data = {
      ...user_data,
      sms_opted_in,
      receive_content_notifications,
      consents_to_sms_marketing,
      email,
      password,
      password_confirmation: password_confirm,
      skip_password_validation,
      phone: phone_number ? `${phone_region.code} ${phone_number}` : undefined,
      acquisition_string,
      initial_service_line: service_line,
      country,
      is_guest
    };

    if (feature_flag) user_data.feature_flag = feature_flag;
    if (phone_number) user_data.phone = `${phone_region.code} ${phone_number}`;
    if (user_info.user_class) user_data.user_class = user_info.user_class;
    if (user_info.partner_code) user_data.partner_code = user_info.partner_code;
    if (user_info.account) user_data.account = user_info.account;

    // axios call is not valid, please use useApi() hook instead
    return axios
      .post('/api/users', user_data, { headers })
      .then(resp => {
        const { id } = resp.data;
        segment.identify(id, email);
        window.dataLayer?.push({ user_id: String(id) });
        return Promise.resolve({ password, ...resp.data });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
  return undefined;
};

export const register_and_set_user = user_info => dispatch => {
  // reset state before sign_up
  dispatch(reset_state());

  return dispatch(register_user(user_info)).then(resp => {
    return dispatch(set_user(resp));
  });
};

export const set_patient = patient => dispatch => {
  return dispatch({ type: SET_PATIENT, patient });
};

export const set_therapist = therapist => dispatch => {
  return dispatch({ type: SET_THERAPIST, therapist });
};

export const save_url = url => dispatch => {
  return dispatch({ type: SAVE_URL, url });
};
