import { PriceModel } from 'localization/types';
import { MEDICATION_PLAN_NAME } from 'constants/textConstants';
import locale from '../localization';
import createPricing from '../localization/createPricing';
import { PlanTitle } from './plans.types';

const country = 'us';
locale.setLanguage(country);

const {
  regularVideo,
  defaultSubpriceMessage,
  weeklyVideoPhone,
  medsTherapyPlanTitle,
  medsTherapyCta,
  medsTherapyCtaChoose,
  medsPlanTitle,
  medicationManagementNYFLPlan,
  currencySymbol,
  prescriberText,
  medicationPrices,
  medication99Prices,
  therapyPrices,
  medicationTherapyPrices,
  nutritionPrices,
  nutritionMedicationPrices,
  matPlanTitle,
  matCounselingPrices,
  matCounselingCta,
  adHocTherapyPrices,
  therapyUKPlan,
  therapyGPPlan
} = locale;

export const offerings = [
  'medication',
  'therapy',
  'medication-therapy',
  'medication-smoking-cessation-treatment',
  'medication-therapy-smoking-cessation-treatment',
  'nutrition-management',
  'nutrition-medication-management',
  'nutrition-management-therapy',
  'nutrition-medication-management-therapy',
  'counseling',
  'counseling',
  'medication-adhd-115-management',
  'medication-adhd-135-management',
  'therapy-nutrition-management',
  '99_med_management',
  '109_med_management',
  '345_meds_therapy',
  '275_therapy',
  '119_med_management',
  '365_meds_therapy',
  '290_therapy',
  'medication-management-85',
  'medication-coaching',
  'coaching',
  'nutrition-management-mm-counseling',
  'nutrition-management-mm-therapy',
  'mat-counseling',
  'medication-coaching-abtest',
  'coaching-abtest',
  'bipolar_115_medication_management',
  'bipolar_135_medication_management',
  'first-ad-hoc-therapy',
  'medication-first-ad-hoc-therapy',
  'medication-second-ad-hoc-therapy',
  'second-ad-hoc-therapy',
  'therapy_2_sessions_monthly',
  'therapy_gp_2_sessions_monthly',
  'therapy_4_sessions_monthly',
  'therapy_gp_4_sessions_monthly',
  'medication_99',
  'medication_management',
  'medication_care_management_b2b',
  'medication_care_management_65_b2b',
  'medication_care_management_insurance_b2b',
  'medication_89',
  'meds_therapy_293',
  'therapy_233',
  'couples_therapy'
] as const;

export type OfferingKey = (typeof offerings)[number];

type PlanType = OfferingKey | 'counseling' | 'legacy' | 'medication-only';

const ctas = {
  THERAPY: 'Start Today',
  MEDS: 'Start Today',
  ADD_COUNSEL: 'Add Care Counseling',
  MEDS_THERAPY: medsTherapyCta,
  MEDS_THERAPY_CHOOSE: medsTherapyCtaChoose,
  ADD_THERAPY: 'Add Therapy',
  ADD_SMOKING_CESSATION: 'Add Smoking Cessation',
  CHOOSE_PLAN: 'Choose this plan',
  MAT_COUNSELING: 'Start Today'
};

export interface Plan {
  mostComprehensive?: boolean;
  title: string;
  name: string;
  description?: string;
  bullets: string[];
  newBullets?: {
    title: string;
    content: string;
  }[];
  insuranceCover?: boolean;
  paymentBullets?: string[];
  bulletsForOud?: string[];
  bulletsForTherapyCentric?: string[];
  price: number;
  priceString?: string;
  monthlyDuration?: number;
  loanString?: string;
  planPayoverTime?: string;
  pricePerWeek?: string;
  pricePerMonth?: string;
  pricePerYear?: string;
  pricePerWeekTotal?: number;
  discountedPrice?: number;
  discountedPricePerWeek?: string;
  discountedPricePerMonth?: string;
  discountedPricePerYear?: string;
  discountedPricePerWeekTotal?: number;
  priceWeekPlusDiscount?: number;
  upsellPrice?: number;
  subprice: string;
  cta?: string;
  ctaChoose?: string;
  id: OfferingKey;
  upsell?: {
    planName?: string;
    x2Effective: string;
    title: string;
    flowTitle: string;
    bullets: string[];
    bulletsForTherapy: string[];
    subprice: string;
    cta: string;
    cancelLabel?: string;
    weeklyPricingUpsellScreenTitle?: string;
  };
  category?: string | null | undefined;
  planId?: number;
  priceCompare?: {
    title: string;
    content: string;
    checkDetail: boolean[];
    btnColor: string;
  };
  isYearly?: boolean;
  monthlyPlan?: Plan;
  isB2bWithCoupon?: boolean;
}

const membershipFee = 30;

const medicationPlan = medsPlanTitle;
const medicationTherapyPlan = medsTherapyPlanTitle;

const therapyPlan: PlanTitle = {
  title: 'Therapy \n ',
  name: 'Therapy'
};

const coupleTherapyPlan: PlanTitle = {
  title: 'Couples Therapy',
  name: 'Couples Therapy'
};

const medicationManagementPlan: PlanTitle = {
  title: 'Medication \nManagement',
  name: 'Medication Management'
};

const medicationCareManagementPlan: PlanTitle = {
  title: MEDICATION_PLAN_NAME,
  name: MEDICATION_PLAN_NAME
};

const medicationCareManagementb2bPlan: PlanTitle = {
  title: MEDICATION_PLAN_NAME,
  name: MEDICATION_PLAN_NAME
};

export const mapPlanToCouponCode: Record<string, string> = {
  therapy: locale.defaultTherapyCode,
  'medication-therapy': locale.defaultMedsTherapyCode,
  'medication-management-85': locale.defaultMedicationManagementNYAndFL,
  'nutrition-management': locale.defaultNutritionCode,
  'nutrition-medication-management': locale.defaultNutritionMedicationCode,
  'medication-coaching': locale.defaultMedicationCoachingCode,
  coaching: locale.defaultCoachingCode,
  'nutrition-management-mm-counseling': locale.defaultNutritionManagementMMCounselingCode,
  'nutrition-management-mm-therapy': locale.defaultNutritionManagementMMTherapy,
  'nutrition-management-therapy': locale.defaultNutritionManagementTherapy,
  'medication-coaching-abtest': locale.defaultMedicationCoachingAbtestCode,
  'coaching-abtest': locale.defaultCoachingAbtestCode,
  'first-ad-hoc-therapy': locale.defaultAdHocTherapyCodeOneTime,
  'mat-counseling': locale.defaultOudCode,
  'second-ad-hoc-therapy': locale.defaultAdHocTherapyCodeOneTime,
  medication: locale.defaultPromoCode,
  medication_99: locale.defaultMedication99Code
};

export const priceWeek = (price: number): string => `${currencySymbol}${price}/week`;
export const longFormatPricePerWeek = (price: number): string => `${currencySymbol}${price} per week`;
export const priceWeekDiscount = (price: number): string => `${currencySymbol}${price}/week for first month`;
export const priceMonth = (price: number): string => `${currencySymbol}${price}/month`;
export const priceMonthDiscount = (price: number): string => `${currencySymbol}${price} for first month`;

const x2EffectiveTherapy = '2X as effective when combined with Therapy.';
const x2EffectiveCoaching = '2X as effective when combined with Coaching.';

export const bullets = {
  evaluationDiagnosisPrescription: `Evaluation, diagnosis, and prescription by a medical ${prescriberText}`,
  medicationDelivery: 'Monthly medication delivery (if prescribed)',
  weeklyVideoPhone,
  therapyWeeklyVideoPhone: 'Weekly video or phone sessions with a licensed therapist',
  medicationRegularVideo: 'Regular video or phone sessions with an assigned care counselor',
  weeklyLiveTherapy: 'Weekly live therapy sessions using proven methods',
  regularPhoneVideo: `Regular video/phone visits with your dedicated ${prescriberText}`,
  ongoingSessionsCounselor: 'Ongoing sessions with your Care Counselor',
  ongoingSessionsProviderCounselor: `Ongoing sessions with your ${prescriberText} & Care Counselor`,
  textChat: 'Access to your care team for questions about scheduling and more',
  evidenceBasedHealth: 'Evidence-based behavioral health counseling & regular progress checks',
  talkTherapy: 'Evidence-based talk therapy',
  unlimitedMessagingTherapist: 'Access to your care team for questions about medications, scheduling, and more',
  psychotherapyEvaluations: 'Regular psychotherapy evaluations to make sure you’re meeting your mental health goals',
  assignedProvider: `An assigned ${prescriberText} and Care Counselor`,
  assignedCoordinator: 'Assigned care coordinator to help navigate the system & insurance issues',
  assignedCoordinatorQuestions: 'Assigned care coordinator to help with administrative and insurance questions',
  recordsAccess: `Your ${prescriberText}s have access to your records for personalized individual care`,
  bookAppointments: 'Book appointments with just a few clicks',
  collaborativeCare: 'Collaborative care for comprehensive mental health treatment',
  privateMessaging: 'Access to your care team for questions about medications, scheduling, and more',
  regularAssessments: `Regular assessments and progress tracking with your ${prescriberText}`,
  regularAssessmentsTherapist: 'Regular assessments and progress tracking with your therapist',
  regularAssessmentsProviderTherapist: `Regular assessments and progress tracking with your ${prescriberText} and therapist`,
  progressTrackingTherapist: 'Regular progress tracking by your therapist',
  progressTrackingProviderTherapist: `Regular progress tracking by your ${prescriberText} & therapist`,
  progressTrackingMedicationTherapist: `Regular progress tracking by ${prescriberText} & therapist`,
  possibleBupropionPrescription:
    'Possible Bupropion prescription, which can help reduce cravings and withdrawal symptoms',
  nicotineReplacementTherapy: 'Nicotine replacement therapy gum can help reduce cravings and withdrawal symptoms',
  monthlyNrtDelivery: 'Monthly delivery, right to your door',
  nicotineReplaceGum: 'Nicotine replacement therapy gum',
  onGoingCareCoordination: 'Ongoing care coordination',
  nutritionRegular: 'Regular video/phone sessions with an assigned nutritionist',
  nutritionPresciberRegular: `Regular video/phone sessions with an assigned nutritionist and ${prescriberText}`,
  nutritionEvidence: 'Evidence-based nutritional & cognitive behavioral therapy counseling',
  nutritionCareTeam: 'Regular progress checks & unlimited messaging with your care team',
  nutritionEvaluation: `Evaluation, diagnosis, & prescription by a medical ${prescriberText}`,
  nutritionDelivery: 'Monthly medication delivery (if prescribed)',
  unlimitedMessagingCareCounselor: 'Unlimited messaging with your care counselor',
  weeklyVideoPhoneCounselor: 'Weekly video/phone sessions with your care counselor',
  nutritionEvidenceUpsell: 'Evidence-based nutritional counseling & regular progress checks',
  medicationManagementEvaluation: `Evaluation, diagnosis and prescription by a medical ${prescriberText} - `,
  medicationManagementUnlimited: `Unlimited messaging with your medical ${prescriberText}`,
  medicationCoachingManagementEvaluation: `Evaluation, diagnosis, and, if appropriate, prescription by a medical ${prescriberText}`,
  medicationTherapyManagementEvaluation: `Evaluation, diagnosis, and, if appropriate, prescription by a medical ${prescriberText}`,
  medicationManagementRegular: 'Regular behavioral health progress checks',
  weeklyVideoPhoneCoach: 'Weekly video or phone sessions with your coach',
  weeklyPhoneVideoCoach: 'Weekly phone/video sessions with your coach',
  weeklyVideoTherapist: 'Weekly video sessions with a licensed therapist',
  monthlyMedicationDeliveryByMail: 'Monthly medication delivery by mail included',
  monthlyMedicationDeliveryNoSuboxone: 'Monthly medication & delivery by mail included. Suboxone is not included',
  regularPrescriberCoach: 'Regular progress tracking by prescriber & coach',
  regularCoach: 'Regular progress tracking by coach',
  evidenceBasedHealthCounseling: 'Evidence-based behavioral health counseling',
  unlimitedMessagingCoach: 'Unlimited messaging with your coach',
  nutritionUpsellEvaluation: `Evaluation, diagnosis, and prescription by a medical ${prescriberText}`,
  nutritionUpsellDelivery: 'Monthly medication and delivery by mail included',
  nutritionUpsellRegularCC: 'Regular video sessions with an assigned counselor',
  nutritionUpsellEvidence: 'Evidence-based behavioral health counseling and regular progress checks',
  nutritionUpsellRegularProgress: `Regular progress tracking by ${prescriberText} & therapist`,
  nutritionUpsellRegularProgressTherapy: 'Regular progress tracking by your therapist',
  nutritionMonthlyShipment: 'Monthly shipment costs and any necessary lab work included',
  nutritionMedicationCost: 'Medication costs can be covered by your insurance plan',
  nutritionEvidenceCbt: 'Evidence-based nutritional & CBT counseling',
  suboxone: 'Suboxone prescription & evidence-based behavioral counseling to support opioid use',
  additionalConcerns: `For any additional health concerns: evaluation, diagnosis & prescription by a medical ${prescriberText}`,
  regular1on1: `Regular 1:1 sessions with your ${prescriberText} and care counselor`,
  confidential: 'Confidential treatment from the privacy of your home',
  evidenceBasedCounselingSessions: 'Weekly evidence-based behavioral counseling sessions',
  counselorRegular: 'Regular video/phone sessions with an assigned care counselor',
  weeklyVideoPhoneTherapistUntil: 'Weekly video/phone sessions with a licensed therapist until no longer needed',
  regularPrescriberTherapist: `Regular progress tracking by ${prescriberText} & therapist`,
  secureMessagingTherapist: 'Secure messaging with your therapist',
  weeklyVideoPhonetherapist: 'Weekly video/phone sessions with a licensed therapist',
  progressTrackingCareCounselor: 'Regular progress tracking by your Care Counselor',
  FullRangeOfInApp: 'Full range of in-app content, including journaling prompts and digital behavioral tools/exercises',
  TopicSpecificCoach: 'Topic-specific coaching on commonly experienced life challenges and stressors',
  secureMessagingCareCounselor: 'Secure messaging with your Care Counselor',
  meditationAccess:
    'Access to hundreds of meditations and other guided audio to help you de-stress, sleep better, focus more, and feel balanced',
  meditationDaily: 'Self-care recommendations and skill-building exercises from Cerebral’s team of experts',
  meditationUpgrade: 'Ability to upgrade anytime to access other Cerebral mental health services',
  meditationYearlyBilled: 'Billed yearly',
  meditationMonthlyBilled: 'Billed monthly',
  meditationPaymentAccess: 'Access to hundreds of meditations to support your wellbeing',
  meditationPaymentSelfCare: 'Self-care recommendations from Cerebral’s team of experts',
  meditationPaymentUpgrade: 'Ability to upgrade anytime to access other Cerebral mental health services',
  nutritionUnlockGlp1: "Unlock long-term weight loss with GLP-1's and evidence-based counseling",
  notNutritionMedication: 'Medication is not included, but can be covered by your insurance',
  nutritionEvidenceCbtCheck: 'Evidence-based nutritional and CBT counseling & regular progress checks',
  nutritionDescriptionEvaluation: `Evaluation, diagnosis & prescription by a medical ${prescriberText}`,
  nutritionDescriptionProgressTracking: 'Regular progress tracking',
  nutritionDescriptionRegularCareCounselor:
    'Regular video/phone sessions with an assigned nutritionist and care counselor',
  nutritionDescriptionEvidenceCareCounselor: 'Evidence-based nutritional & behavioral health counseling',
  nutritionDescriptionProgress: 'Regular progress checks',
  premiumTherapyTime: 'Therapy at a time that suits you',
  premiumTherapySession: 'Video, phone or text sessions',
  premiumTherapyCareCoordinator: 'Dedicated care coordinator',
  premiumTherapyReview: 'Tailored reviews to track your progress',
  premiumPrivateTherapyEverything: 'Everything in the Therapy plans, plus:',
  premiumPrivateTherapyGP: 'Specialist private GPs with experience in mental health',
  premiumPrivateTherapyAssessment: 'Initial 30 minute GP assessment',
  premiumPrivateTherapyDelivery: 'Prescription medication delivered monthly to your door (if required)',
  premiumPrivateTherapyFollow: 'Regular follow up appointments and monitoring',
  eligibleInsuranceBenefits: 'Patients eligible for insurance benefits may qualify for plans as low as $30/month',
  regularVisitMedication: 'Regular visits with your prescriber',
  medicationEvaluation: 'Evaluation, diagnosis, and monthly medication delivery (if prescribed)',
  prescriberDiagnosis: `Get an evaluation & diagnosis by a medical ${prescriberText}`,
  developOngoing: 'Develop an ongoing care plan together',
  medicationPrescriber: 'Medication prescribed as appropriate',
  regularCheckins: `Regular check-ins with your ${prescriberText} by phone or video`,
  regularProgress:
    'Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place'
};

export const bulletsForTherapy = {
  chooseTherapist: 'Choose the right therapist for your needs',
  weeklyVideoPhoneTherapy: 'Deep, transformative 45-minute sessions with your therapist by phone or video',
  messagingTherapy: 'Develop an ongoing care plan together',
  regularProgress:
    'Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place'
};

export const newMedicationTherapyBullets = [
  'Choose the right therapist for your needs',
  'Deep, transformative 45-minute sessions with your therapist by phone or video',
  'Get an evaluation & diagnosis by a medical prescriber',
  'Develop an ongoing care plan together',
  'Medication prescribed as appropriate',
  'Regular check-ins with your prescriber by phone or video',
  'Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place'
];
export const newMedication99Bullets = [
  'Get an evaluation & diagnosis by a medical prescriber',
  'Develop an ongoing care plan together',
  'Medication prescribed as appropriate',
  'Regular check-ins with your prescriber by phone or video',
  'Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place'
];
export const newTherapyBullets = [
  'Choose the right therapist for your needs',
  'Deep, transformative 45-minute sessions with your therapist by phone or video',
  'Develop an ongoing care plan together',
  'Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place'
];
export const newMedicationCCBullets = [
  'Get an evaluation & diagnosis by a medical prescriber',
  'Develop an ongoing care plan together',
  'Medication prescribed as appropriate',
  'Regular check-ins with your prescriber and care counselor by phone or video',
  'Convenient access to appointment scheduling, progress monitoring, messaging & self-care resources all in one place'
];

export const bulletsOfB2bPlan = {
  counseling: [
    bullets.evidenceBasedCounselingSessions,
    bullets.secureMessagingCareCounselor,
    bullets.TopicSpecificCoach,
    bullets.FullRangeOfInApp,
    bullets.progressTrackingCareCounselor
  ],
  therapy: newTherapyBullets,
  'medication-therapy': newMedicationTherapyBullets,
  medication: newMedicationCCBullets,
  medication_management: newMedicationCCBullets,
  medication_care_management_b2b: newMedicationCCBullets,
  medication_care_management_65_b2b: newMedicationCCBullets,
  medication_care_management_insurance_b2b: newMedicationCCBullets,
  medication_89: newMedicationCCBullets,
  meds_therapy_293: newMedicationTherapyBullets,
  therapy_233: newTherapyBullets
};

export interface PlanPriceModeDetail {
  pricePerWeekTotal: number;
  discountedPricePerWeekTotal: number;
  pricePerWeek: string;
  longFormatPricePerWeek: string;
  discountedPricePerWeek: string;
  price: number;
  discountedPrice: number;
  pricePerMonth: string;
  discountedPricePerMonth: string;
  priceWeekPlusDiscount: number;
}

export const planPriceModeDetail = (price: PriceModel): PlanPriceModeDetail => ({
  pricePerWeekTotal: price.priceWeek,
  discountedPricePerWeekTotal: price.priceWeekDiscount,
  pricePerWeek: priceWeek(price.priceWeek),
  longFormatPricePerWeek: longFormatPricePerWeek(price.priceWeek),
  discountedPricePerWeek: priceWeekDiscount(price.priceWeekDiscount),
  price: price.priceMonth,
  discountedPrice: price.priceMonthDiscount,
  pricePerMonth: priceMonth(price.priceMonth),
  discountedPricePerMonth: priceMonthDiscount(price.priceMonthDiscount),
  priceWeekPlusDiscount: price.priceWeekDiscount + price.priceWeek
});

const medicationOnly: Plan = {
  id: 'medication',
  mostComprehensive: false,
  title: 'Medication &\nCare',
  name: 'Medication & Care',
  description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
  bullets: [
    bullets.evaluationDiagnosisPrescription,
    bullets.medicationDelivery,
    bullets.regularPhoneVideo,
    bullets.textChat
  ],
  ...planPriceModeDetail(medicationPrices),
  subprice: defaultSubpriceMessage,
  cta: ctas.MEDS,
  upsell: {
    x2Effective: x2EffectiveTherapy,
    title: 'Add Therapy to improve \nyour care',
    flowTitle: 'Therapy',
    bullets: [bullets.weeklyLiveTherapy, bullets.privateMessaging, bullets.psychotherapyEvaluations],
    bulletsForTherapy: [
      bulletsForTherapy.chooseTherapist,
      bulletsForTherapy.weeklyVideoPhoneTherapy,
      bulletsForTherapy.messagingTherapy,
      bulletsForTherapy.regularProgress
    ],
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  }
};

const cashPlans: Record<PlanType, Plan> = {
  medication_care_management_b2b: {
    id: 'medication_care_management_b2b',
    mostComprehensive: false,
    title: medicationCareManagementb2bPlan.title,
    name: medicationCareManagementb2bPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText}.`,
    bullets: bulletsOfB2bPlan.medication_care_management_b2b,
    price: 85,
    discountedPrice: 0,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  medication_care_management_65_b2b: {
    id: 'medication_care_management_65_b2b',
    mostComprehensive: false,
    title: medicationCareManagementb2bPlan.title,
    name: medicationCareManagementb2bPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText}.`,
    bullets: bulletsOfB2bPlan.medication_care_management_65_b2b,
    price: 65,
    discountedPrice: 0,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  medication_care_management_insurance_b2b: {
    id: 'medication_care_management_insurance_b2b',
    mostComprehensive: false,
    title: medicationCareManagementb2bPlan.title,
    name: medicationCareManagementb2bPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText}.`,
    bullets: bulletsOfB2bPlan.medication_care_management_insurance_b2b,
    price: 30,
    discountedPrice: 0,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'medication-therapy': {
    id: 'medication-therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.medicationTherapyManagementEvaluation,
      bullets.therapyWeeklyVideoPhone,
      bullets.monthlyMedicationDeliveryByMail,
      bullets.textChat,
      bullets.progressTrackingMedicationTherapist
    ],
    bulletsForOud: [
      bullets.medicationTherapyManagementEvaluation,
      bullets.weeklyVideoTherapist,
      bullets.monthlyMedicationDeliveryNoSuboxone,
      bullets.progressTrackingMedicationTherapist
    ],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    ctaChoose: medsTherapyCtaChoose,
    priceCompare: {
      title: 'Therapy +<br/>Medication',
      content: 'Licensed Therapist<br/>+<br/>Prescriber',
      checkDetail: [true, false, true, true, true, true, true],
      btnColor: 'linear-gradient(63.26deg, rgba(119, 35, 255, 0.96) -21.37%, #85ABEE 132.47%)'
    }
  },
  'medication-first-ad-hoc-therapy': {
    id: 'medication-first-ad-hoc-therapy',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: 'Medication + Care Counseling + Trial Therapy',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      bullets.medicationRegularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(medication99Prices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    },
    priceCompare: {
      title: 'Medication +<br/>Care Counseling',
      content: 'Care Counselor<br/>+<br/>Prescriber',
      checkDetail: [false, true, true, true, true, true, false],
      btnColor: 'linear-gradient(64.77deg, #004DD7 37.21%, #85ABEE 108.9%)'
    }
  },
  'medication-second-ad-hoc-therapy': {
    id: 'medication-second-ad-hoc-therapy',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: 'Medication + Care Counseling + Trial Therapy',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      bullets.medicationRegularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(medication99Prices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    },
    priceCompare: {
      title: 'Medication +<br/>Care Counseling',
      content: 'Care Counselor<br/>+<br/>Prescriber',
      checkDetail: [false, true, true, true, true, true, false],
      btnColor: 'linear-gradient(64.77deg, #004DD7 37.21%, #85ABEE 108.9%)'
    }
  },
  'medication-management-85': {
    id: 'medication-management-85',
    mostComprehensive: false,
    title: medicationManagementPlan.title,
    name: medicationManagementPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.medicationManagementEvaluation,
      bullets.medicationManagementUnlimited,
      bullets.medicationManagementRegular
    ],
    ...planPriceModeDetail(medicationManagementNYFLPlan),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  medication: {
    id: 'medication',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      bullets.medicationRegularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(medicationPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      weeklyPricingUpsellScreenTitle: 'Add therapy to improve your care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    },
    priceCompare: {
      title: 'Medication +<br/>Care Counseling',
      content: 'Care Counselor<br/>+<br/>Prescriber',
      checkDetail: [false, true, true, true, true, true, false],
      btnColor: 'linear-gradient(64.77deg, #004DD7 37.21%, #85ABEE 108.9%)'
    }
  },
  therapy: {
    id: 'therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.therapyWeeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(therapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    ctaChoose: 'Start Therapy Today',
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve your care',
      weeklyPricingUpsellScreenTitle: 'Add medication to improve your care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    },
    priceCompare: {
      title: 'Therapy',
      content: 'Licensed Therapist',
      checkDetail: [true, false, false, true, true, true, false],
      btnColor: 'linear-gradient(32.69deg, #6786FF 48.2%, #94E3F6 102.83%)'
    }
  },
  counseling: {
    id: 'counseling',
    mostComprehensive: false,
    title: 'Care \nCounseling',
    name: 'Care Counseling',
    bullets: [bullets.weeklyVideoPhoneCounselor, bullets.unlimitedMessagingCareCounselor, bullets.evidenceBasedHealth],
    paymentBullets: [
      bullets.weeklyVideoPhoneCounselor,
      bullets.unlimitedMessagingCareCounselor,
      bullets.evidenceBasedHealth
    ],
    price: 0,
    discountedPrice: 0,
    subprice: '',
    cta: ctas.MEDS
  },
  medication_management: {
    id: 'medication_management',
    mostComprehensive: false,
    title: medicationCareManagementb2bPlan.title,
    name: medicationCareManagementb2bPlan.name,
    bullets: bulletsOfB2bPlan.medication_management,
    paymentBullets: bulletsOfB2bPlan.medication_management,
    price: 0,
    discountedPrice: 0,
    subprice: '',
    cta: ctas.MEDS
  },
  'medication-only': medicationOnly,
  legacy: {
    id: 'medication',
    mostComprehensive: false,
    title: 'Cerebral Membership',
    name: 'Cerebral Membership',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.assignedProvider,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.ongoingSessionsProviderCounselor
    ],
    paymentBullets: [
      bullets.assignedProvider,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.ongoingSessionsProviderCounselor
    ],
    price: 99,
    discountedPrice: 30,
    subprice: 'Billed monthly <br />Free Shipping<br />FSA / HSA eligible<br /> Cancel anytime'
  },
  'nutrition-management': {
    id: 'nutrition-management',
    mostComprehensive: false,
    title: 'Nutrition \nManagement',
    name: 'Nutrition Management',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidence, bullets.nutritionCareTeam],
    upsellPrice: locale.nutritionManagementUpsellPriceDefault,
    price: locale.nutritionManagementPriceDefault,
    pricePerWeek: priceWeek(nutritionPrices.priceWeek),
    pricePerMonth: priceMonth(nutritionPrices.priceMonth),
    pricePerWeekTotal: nutritionPrices.priceWeek,
    discountedPrice: nutritionPrices.priceMonthDiscount,
    discountedPricePerWeek: priceWeekDiscount(nutritionPrices.priceWeekDiscount),
    discountedPricePerMonth: priceMonthDiscount(nutritionPrices.priceMonthDiscount),
    discountedPricePerWeekTotal: nutritionPrices.priceWeekDiscount,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [bullets.weeklyVideoPhonetherapist, bullets.textChat, 'Regular progress tracking by your therapist'],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'nutrition-management-therapy': {
    id: 'nutrition-management-therapy',
    mostComprehensive: false,
    title: 'Nutrition \nManagement',
    name: 'Nutrition Management',
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoTherapist, bullets.textChat, bullets.nutritionUpsellRegularProgressTherapy],
    paymentBullets: [
      bullets.nutritionRegular,
      bullets.weeklyVideoPhonetherapist,
      bullets.nutritionEvidence,
      bullets.nutritionCareTeam,
      bullets.textChat
    ],
    upsellPrice: locale.nutritionManagementUpsellPriceDefault,
    ...planPriceModeDetail(createPricing(328, 124)),
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      planName: 'Therapy',
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [bullets.weeklyVideoPhonetherapist, bullets.textChat, 'Regular progress tracking by your therapist'],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'nutrition-medication-management': {
    id: 'nutrition-medication-management',
    mostComprehensive: false,
    title: 'Weight Loss Medication',
    name: 'Weight Loss Medication',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [
      bullets.nutritionEvaluation,
      bullets.nutritionDelivery,
      bullets.nutritionRegular,
      bullets.nutritionEvidence,
      bullets.nutritionCareTeam
    ],
    upsellPrice: locale.nutritionMedicationUpsellPriceDefault,
    price: locale.nutritionMedicationPriceDefault,
    pricePerWeek: priceWeek(nutritionMedicationPrices.priceWeek),
    pricePerMonth: priceMonth(nutritionMedicationPrices.priceMonth),
    pricePerWeekTotal: nutritionMedicationPrices.priceWeek,
    discountedPrice: nutritionMedicationPrices.priceMonthDiscount,
    discountedPricePerWeek: priceWeekDiscount(nutritionMedicationPrices.priceWeekDiscount),
    discountedPricePerMonth: priceMonthDiscount(nutritionMedicationPrices.priceMonthDiscount),
    discountedPricePerWeekTotal: nutritionMedicationPrices.priceWeekDiscount,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [bullets.weeklyVideoPhonetherapist, bullets.textChat, 'Regular progress tracking by your therapist'],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'nutrition-medication-management-therapy': {
    id: 'nutrition-medication-management-therapy',
    mostComprehensive: false,
    title: 'Weight Loss Medication + Therapy',
    name: 'Weight Loss Medication + Therapy',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [
      bullets.nutritionEvaluation,
      bullets.nutritionDelivery,
      bullets.nutritionRegular,
      bullets.nutritionEvidence,
      bullets.nutritionCareTeam
    ],
    upsellPrice: locale.nutritionMedicationUpsellPriceDefault,
    price: locale.nutritionMedicationPriceDefault,
    pricePerWeek: priceWeek(nutritionMedicationPrices.priceWeek),
    pricePerMonth: priceMonth(nutritionMedicationPrices.priceMonth),
    pricePerWeekTotal: nutritionMedicationPrices.priceWeek,
    discountedPrice: nutritionMedicationPrices.priceMonthDiscount,
    discountedPricePerWeek: priceWeekDiscount(nutritionMedicationPrices.priceWeekDiscount),
    discountedPricePerMonth: priceMonthDiscount(nutritionMedicationPrices.priceMonthDiscount),
    discountedPricePerWeekTotal: nutritionMedicationPrices.priceWeekDiscount,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [bullets.weeklyVideoPhonetherapist, bullets.textChat, 'Regular progress tracking by your therapist'],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'medication-smoking-cessation-treatment': {
    id: 'medication-smoking-cessation-treatment',
    mostComprehensive: false,
    title: 'Would you like to add smoking cessation to your plan?',
    name: 'Add Smoking Cessation \nTreatment',
    description:
      'Smoking is known to contribute to conditions like the ones you’re experiencing. We can help you quit.',
    bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
    ...planPriceModeDetail(medicationPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Smoking Cessation \nTreatment',
      flowTitle: 'Add Smoking Cessation \nTreatment',
      bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_SMOKING_CESSATION
    }
  },
  'medication-therapy-smoking-cessation-treatment': {
    id: 'medication-therapy-smoking-cessation-treatment',
    mostComprehensive: false,
    title: 'Would you like to add smoking cessation to your plan?',
    name: 'Add Smoking Cessation \nTreatment',
    description:
      'Smoking is known to contribute to conditions like the ones you’re experiencing. We can help you quit.',
    bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    upsell: {
      x2Effective: '',
      title: 'Add Smoking Cessation \nTreatment',
      flowTitle: 'Add Smoking Cessation \nTreatment',
      bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_SMOKING_CESSATION
    }
  },
  'medication-adhd-115-management': {
    id: 'medication-adhd-115-management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(115, 41)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    }
  },
  'medication-adhd-135-management': {
    id: 'medication-adhd-135-management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(135, 48)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    }
  },
  'therapy-nutrition-management': {
    id: 'therapy-nutrition-management',
    mostComprehensive: false,
    title: 'Would you like to upgrade your plan to include Nutrition Management?',
    name: 'Therapy + Nutrition',
    description: '',
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.textChat],
    ...planPriceModeDetail(therapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    upsell: {
      x2Effective: '',
      title: 'Nutrition Management',
      flowTitle: '',
      bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.textChat],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.CHOOSE_PLAN
    }
  },
  '99_med_management': {
    id: '99_med_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(99, 30)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    }
  },
  '109_med_management': {
    id: '109_med_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(109, 30)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    }
  },
  '345_meds_therapy': {
    id: '345_meds_therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.talkTherapy,
      bullets.weeklyVideoPhone,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.progressTrackingProviderTherapist
    ],
    ...planPriceModeDetail(createPricing(345, 139)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  },
  '275_therapy': {
    id: '275_therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(createPricing(275, 99)),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  '119_med_management': {
    id: '119_med_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(119, 30)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    }
  },
  '365_meds_therapy': {
    id: '365_meds_therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.talkTherapy,
      bullets.weeklyVideoPhone,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.progressTrackingProviderTherapist
    ],
    ...planPriceModeDetail(createPricing(365, 139)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  },
  '290_therapy': {
    id: '290_therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(createPricing(290, 99)),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  'medication-coaching': {
    id: 'medication-coaching',
    mostComprehensive: true,
    title: 'Medication + \nCoaching',
    name: 'Medication + Coaching',
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [
      bullets.medicationCoachingManagementEvaluation,
      bullets.weeklyVideoPhoneCoach,
      bullets.monthlyMedicationDeliveryByMail,
      bullets.textChat,
      bullets.regularPrescriberCoach
    ],
    paymentBullets: [
      bullets.medicationCoachingManagementEvaluation,
      bullets.weeklyVideoPhoneCoach,
      bullets.monthlyMedicationDeliveryByMail,
      bullets.textChat,
      bullets.regularPrescriberCoach
    ],
    ...planPriceModeDetail(createPricing(195, 79)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  coaching: {
    id: 'coaching',
    mostComprehensive: false,
    title: 'Coaching \n ',
    name: 'Coaching',
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [
      bullets.weeklyVideoPhoneCoach,
      bullets.unlimitedMessagingCoach,
      bullets.evidenceBasedHealthCounseling,
      bullets.regularCoach
    ],
    paymentBullets: [
      bullets.weeklyVideoPhoneCoach,
      bullets.unlimitedMessagingCoach,
      bullets.evidenceBasedHealthCounseling,
      bullets.regularCoach
    ],
    ...planPriceModeDetail(createPricing(145, 59)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'nutrition-management-mm-counseling': {
    id: 'nutrition-management-mm-counseling',
    mostComprehensive: false,
    title: 'Nutrition Management +  Medication Management & Counseling',
    name: 'Nutrition Management +  Medication Management & Counseling',
    description: `Receive collaborative care by a ${prescriberText} & a care counselor.`,
    bullets: [
      bullets.nutritionUpsellEvaluation,
      bullets.nutritionUpsellDelivery,
      bullets.nutritionUpsellRegularCC,
      bullets.nutritionUpsellEvidence
    ],
    paymentBullets: [
      bullets.nutritionUpsellEvaluation,
      bullets.nutritionDescriptionRegularCareCounselor,
      bullets.textChat,
      bullets.nutritionDescriptionEvidenceCareCounselor,
      bullets.nutritionDescriptionProgress,
      bullets.nutritionDelivery
    ],
    upsell: {
      planName: 'Medication + Care Counseling',
      x2Effective: '',
      bullets: [],
      bulletsForTherapy: [],
      cta: '',
      flowTitle: '',
      subprice: '',
      title: '',
      cancelLabel: ''
    },
    ...planPriceModeDetail(createPricing(154, 55)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'nutrition-management-mm-therapy': {
    id: 'nutrition-management-mm-therapy',
    mostComprehensive: false,
    title: 'Nutrition Management +  Medication Management & Therapy',
    name: 'Nutrition Management + Medication Management & Therapy',
    description: `Receive collaborative care by a ${prescriberText} & a licensed therapist.`,
    bullets: [
      bullets.nutritionUpsellEvaluation,
      bullets.nutritionUpsellDelivery,
      bullets.weeklyVideoTherapist,
      bullets.textChat,
      bullets.nutritionUpsellRegularProgress
    ],
    paymentBullets: [
      bullets.nutritionUpsellEvaluation,
      bullets.nutritionRegular,
      bullets.weeklyVideoPhonetherapist,
      bullets.nutritionCareTeam,
      bullets.textChat,
      bullets.nutritionEvidenceCbt,
      bullets.nutritionUpsellRegularProgress,
      bullets.nutritionDelivery
    ],
    upsell: {
      planName: 'Medication + Therapy',
      x2Effective: '',
      bullets: [],
      bulletsForTherapy: [],
      cta: '',
      flowTitle: '',
      subprice: '',
      title: '',
      cancelLabel: ''
    },
    ...planPriceModeDetail(createPricing(394, 164)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'medication-coaching-abtest': {
    id: 'medication-coaching-abtest',
    mostComprehensive: true,
    title: 'Medication + \nCoaching',
    name: 'Medication + Coaching',
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [
      bullets.medicationCoachingManagementEvaluation,
      bullets.weeklyVideoPhoneCoach,
      bullets.monthlyMedicationDeliveryByMail,
      bullets.textChat,
      bullets.regularPrescriberCoach
    ],
    paymentBullets: [
      bullets.medicationCoachingManagementEvaluation,
      bullets.weeklyVideoPhoneCoach,
      bullets.monthlyMedicationDeliveryByMail,
      bullets.textChat,
      bullets.regularPrescriberCoach
    ],
    ...planPriceModeDetail(createPricing(210, 89)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'coaching-abtest': {
    id: 'coaching-abtest',
    mostComprehensive: false,
    title: 'Coaching \n ',
    name: 'Coaching',
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [
      bullets.weeklyPhoneVideoCoach,
      bullets.unlimitedMessagingCoach,
      bullets.evidenceBasedHealthCounseling,
      bullets.regularCoach
    ],
    paymentBullets: [
      bullets.weeklyPhoneVideoCoach,
      bullets.unlimitedMessagingCoach,
      bullets.evidenceBasedHealthCounseling,
      bullets.regularCoach
    ],
    ...planPriceModeDetail(createPricing(160, 70)),

    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'mat-counseling': {
    id: 'mat-counseling',
    mostComprehensive: false,
    title: matPlanTitle.title,
    name: matPlanTitle.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.suboxone,
      bullets.additionalConcerns,
      bullets.regular1on1,
      bullets.confidential,
      bullets.eligibleInsuranceBenefits
    ],
    price: matCounselingPrices.priceMonth,
    discountedPrice: matCounselingPrices.priceMonthDiscount,
    pricePerMonth: priceMonth(matCounselingPrices.priceMonth),
    discountedPricePerMonth: priceMonth(matCounselingPrices.priceMonthDiscount),
    subprice: defaultSubpriceMessage,
    cta: ctas.MAT_COUNSELING,
    ctaChoose: matCounselingCta
  },
  bipolar_115_medication_management: {
    id: 'bipolar_115_medication_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(115, 41)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  bipolar_135_medication_management: {
    id: 'bipolar_135_medication_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(135, 48)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  'first-ad-hoc-therapy': {
    id: 'first-ad-hoc-therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: 'One-time Therapy Visit',
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.therapyWeeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(adHocTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    ctaChoose: 'Start Therapy Today',
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    },
    priceCompare: {
      title: 'Therapy',
      content: 'Licensed Therapist',
      checkDetail: [true, false, false, true, true, true, true],
      btnColor: 'linear-gradient(32.69deg, #6786FF 48.2%, #94E3F6 102.83%)'
    }
  },
  'second-ad-hoc-therapy': {
    id: 'second-ad-hoc-therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: 'One-time Therapy Visit',
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.therapyWeeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(adHocTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    ctaChoose: 'Start Therapy Today',
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      bulletsForTherapy: [],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    },
    priceCompare: {
      title: 'Therapy',
      content: 'Licensed Therapist',
      checkDetail: [true, false, false, true, true, true, true],
      btnColor: 'linear-gradient(32.69deg, #6786FF 48.2%, #94E3F6 102.83%)'
    }
  },
  therapy_2_sessions_monthly: {
    id: 'therapy_2_sessions_monthly',
    mostComprehensive: false,
    title: therapyUKPlan?.title,
    name: therapyUKPlan?.name,
    bullets: [
      bullets.premiumTherapyTime,
      bullets.premiumTherapySession,
      bullets.premiumTherapyCareCoordinator,
      bullets.textChat,
      bullets.premiumTherapyReview
    ],
    paymentBullets: [
      bullets.premiumTherapyTime,
      bullets.premiumTherapySession,
      bullets.premiumTherapyCareCoordinator,
      bullets.textChat,
      bullets.premiumTherapyReview
    ],
    ...planPriceModeDetail(createPricing(79, 29)),
    pricePerMonth: '£79 - £249/month',
    discountedPricePerMonth: '£29 - £99',
    subprice: '/ for your first month',
    cta: ctas.THERAPY
  },
  therapy_gp_2_sessions_monthly: {
    id: 'therapy_gp_2_sessions_monthly',
    mostComprehensive: true,
    title: therapyGPPlan?.title,
    name: therapyGPPlan?.name,
    bullets: [
      bullets.premiumPrivateTherapyEverything,
      bullets.premiumPrivateTherapyGP,
      bullets.premiumPrivateTherapyAssessment,
      bullets.premiumPrivateTherapyDelivery,
      bullets.premiumPrivateTherapyFollow
    ],
    paymentBullets: [
      bullets.premiumPrivateTherapyEverything,
      bullets.premiumPrivateTherapyGP,
      bullets.premiumPrivateTherapyAssessment,
      bullets.premiumPrivateTherapyDelivery,
      bullets.premiumPrivateTherapyFollow
    ],
    ...planPriceModeDetail(createPricing(149, 39)),
    pricePerMonth: '£149 - £329/month',
    discountedPricePerMonth: '£39 - £149',
    subprice: '/ for your first month',
    cta: ctas.THERAPY
  },
  medication_99: {
    id: 'medication_99',
    mostComprehensive: false,
    title: medicationCareManagementPlan.title,
    name: medicationCareManagementPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText}.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      bullets.medicationRegularVideo,
      bullets.evidenceBasedHealth,
      bullets.textChat
    ],
    paymentBullets: [
      `A ${prescriberText}`,
      bullets.medicationDelivery,
      bullets.textChat,
      `Regular video/phone visits with your ${prescriberText}`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(99, 49)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      weeklyPricingUpsellScreenTitle: 'Add therapy to improve your care',
      flowTitle: 'Therapy',
      bullets: [bullets.weeklyLiveTherapy, bullets.privateMessaging, bullets.psychotherapyEvaluations],
      bulletsForTherapy: [
        bulletsForTherapy.chooseTherapist,
        bulletsForTherapy.weeklyVideoPhoneTherapy,
        bulletsForTherapy.messagingTherapy,
        bulletsForTherapy.regularProgress
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY_CHOOSE
    }
  },
  therapy_4_sessions_monthly: {
    id: 'therapy_4_sessions_monthly',
    mostComprehensive: false,
    title: 'Premium Therapy',
    name: 'Premium Therapy',
    bullets: [
      bullets.premiumTherapyTime,
      bullets.premiumTherapySession,
      bullets.premiumTherapyCareCoordinator,
      bullets.textChat,
      bullets.premiumTherapyReview
    ],
    paymentBullets: [
      bullets.premiumTherapyTime,
      bullets.premiumTherapySession,
      bullets.premiumTherapyCareCoordinator,
      bullets.textChat,
      bullets.premiumTherapyReview
    ],
    ...planPriceModeDetail(createPricing(249, 99)),
    pricePerMonth: '£99 - £249/month',
    discountedPricePerMonth: '£99 - £249',
    subprice: '/ for your first month',
    cta: ctas.THERAPY
  },
  therapy_gp_4_sessions_monthly: {
    id: 'therapy_gp_4_sessions_monthly',
    mostComprehensive: true,
    title: 'Premium Therapy + Private GP',
    name: 'Premium Therapy + Private GP',
    bullets: [
      bullets.premiumPrivateTherapyEverything,
      bullets.premiumPrivateTherapyGP,
      bullets.premiumPrivateTherapyAssessment,
      bullets.premiumPrivateTherapyDelivery,
      bullets.premiumPrivateTherapyFollow
    ],
    paymentBullets: [
      bullets.premiumPrivateTherapyEverything,
      bullets.premiumPrivateTherapyGP,
      bullets.premiumPrivateTherapyAssessment,
      bullets.premiumPrivateTherapyDelivery,
      bullets.premiumPrivateTherapyFollow
    ],
    ...planPriceModeDetail(createPricing(329, 149)),
    pricePerMonth: '£149 - £329/month',
    discountedPricePerMonth: '£149 - £349',
    subprice: '/ for your first month',
    cta: ctas.THERAPY
  },
  medication_89: {
    id: 'medication_89',
    mostComprehensive: false,
    title: medicationCareManagementb2bPlan.title,
    name: medicationCareManagementb2bPlan.name,
    bullets: bulletsOfB2bPlan.medication_management,
    paymentBullets: bulletsOfB2bPlan.medication_management,
    price: 89,
    discountedPrice: 0,
    subprice: '',
    cta: ctas.MEDS
  },
  therapy_233: {
    id: 'therapy_233',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.therapyWeeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(therapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    ctaChoose: 'Start Therapy Today',
    priceCompare: {
      title: 'Therapy',
      content: 'Licensed Therapist',
      checkDetail: [true, false, false, true, true, true, false],
      btnColor: 'linear-gradient(32.69deg, #6786FF 48.2%, #94E3F6 102.83%)'
    }
  },
  meds_therapy_293: {
    id: 'meds_therapy_293',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.medicationTherapyManagementEvaluation,
      bullets.therapyWeeklyVideoPhone,
      bullets.monthlyMedicationDeliveryByMail,
      bullets.textChat,
      bullets.progressTrackingMedicationTherapist
    ],
    bulletsForOud: [
      bullets.medicationTherapyManagementEvaluation,
      bullets.weeklyVideoTherapist,
      bullets.monthlyMedicationDeliveryNoSuboxone,
      bullets.progressTrackingMedicationTherapist
    ],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    ctaChoose: medsTherapyCtaChoose,
    priceCompare: {
      title: 'Medication +<br/>Therapy',
      content: 'Licensed Therapist<br/>+<br/>Prescriber',
      checkDetail: [true, false, true, true, true, true, true],
      btnColor: 'linear-gradient(63.26deg, rgba(119, 35, 255, 0.96) -21.37%, #85ABEE 132.47%)'
    }
  },
  couples_therapy: {
    id: 'couples_therapy',
    mostComprehensive: false,
    title: coupleTherapyPlan.title,
    name: coupleTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      'Professional guidance from a highly qualified, compassionate therapist',
      '45-minute online therapy sessions with your partner at least 2x a month',
      'Personalized treatment plan and monthly assessments to track progress',
      'Convenient online appointment scheduling, progress tracking, extra resources, and messaging'
    ],
    paymentBullets: [
      'Professional guidance from a highly qualified, compassionate therapist',
      '45-minute online therapy sessions with your partner at least 2x a month',
      'Personalized treatment plan and monthly assessments to track progress',
      'Convenient online appointment scheduling, progress tracking, extra resources, and messaging'
    ],
    ...planPriceModeDetail(medicationPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY
  }
};

cashPlans['first-ad-hoc-therapy'].monthlyPlan = cashPlans.therapy;
cashPlans['first-ad-hoc-therapy'].discountedPricePerMonth = priceMonthDiscount(therapyPrices.priceMonthDiscount);
cashPlans['first-ad-hoc-therapy'].discountedPricePerWeek = priceWeekDiscount(therapyPrices.priceWeekDiscount);
cashPlans['first-ad-hoc-therapy'].pricePerMonth = priceMonth(therapyPrices.priceMonth);
cashPlans['first-ad-hoc-therapy'].pricePerWeek = priceWeek(therapyPrices.priceWeek);

const insurancePlans: Partial<Record<PlanType, Plan>> = {
  medication_care_management_insurance_b2b: {
    id: 'medication_care_management_insurance_b2b',
    mostComprehensive: false,
    title: medicationCareManagementb2bPlan.title,
    name: medicationCareManagementb2bPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText}.`,
    bullets: bulletsOfB2bPlan.medication_care_management_insurance_b2b,
    price: 30,
    discountedPrice: 0,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'medication-therapy': {
    id: 'medication-therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.weeklyVideoPhone,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.progressTrackingProviderTherapist
    ],
    bulletsForOud: [
      bullets.medicationTherapyManagementEvaluation,
      bullets.weeklyVideoTherapist,
      bullets.monthlyMedicationDeliveryNoSuboxone,
      bullets.progressTrackingMedicationTherapist
    ],
    paymentBullets: [
      bullets.regularPhoneVideo,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.regularAssessmentsProviderTherapist,
      bullets.talkTherapy,
      bullets.assignedCoordinatorQuestions
    ],
    price: 30,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(30),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  },
  medication: {
    id: 'medication',
    mostComprehensive: false,
    title: 'Medication &\nCare',
    name: 'Medication & Care',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      bullets.counselorRegular,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `Regular video/phone visits with your dedicated ${prescriberText}`,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.regularAssessments,
      bullets.assignedCoordinator
    ],
    price: membershipFee,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(membershipFee),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  medication_99: {
    id: 'medication_99',
    mostComprehensive: false,
    title: MEDICATION_PLAN_NAME,
    name: MEDICATION_PLAN_NAME,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      bullets.counselorRegular,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `Regular video/phone visits with your dedicated ${prescriberText}`,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.regularAssessments,
      bullets.assignedCoordinator
    ],
    price: 30,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(30),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  therapy: {
    id: 'therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.unlimitedMessagingTherapist,
      bullets.regularAssessmentsTherapist,
      bullets.talkTherapy,
      bullets.assignedCoordinator
    ],
    price: 30,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(30),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY
  },
  'mat-counseling': {
    id: 'mat-counseling',
    mostComprehensive: false,
    title: 'Medication-Assisted Treatment',
    name: 'Medication-Assisted Treatment',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [bullets.suboxone, bullets.additionalConcerns, bullets.regular1on1, bullets.confidential],
    price: membershipFee,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(membershipFee),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.MAT_COUNSELING
  },
  'medication-only': medicationOnly
};

export const isNotSupportInsurancePayment = (offeringKey: string): boolean => {
  return [
    'nutrition-management',
    'nutrition-medication-management',
    'nutrition-management-therapy',
    'nutrition-medication-management-therapy',
    'medication-management-85',
    'therapy-nutrition-management'
  ].includes(offeringKey);
};

export const upSellPlans = {
  nutrition: {
    x2Effective: '',
    title: 'Nutrition Management',
    flowTitle: '',
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.textChat],
    bulletsForTherapy: [],
    subprice: defaultSubpriceMessage,
    cta: ctas.CHOOSE_PLAN,
    price: nutritionPrices.priceMonth,
    discountedPrice: nutritionPrices.priceMonthDiscount
  },
  coaching: {
    x2Effective: x2EffectiveCoaching,
    title: 'Add Coaching to \nimprove your care',
    flowTitle: '',
    bullets: [
      'Weekly one-on-one sessions with a coach custom matched to you',
      bullets.privateMessaging,
      'Regular coaching evaluations to make sure you’re meeting your mental health goals'
    ],
    bulletsForTherapy: [],
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: 'Start Meds+Coaching for $79 today',
    price: 0,
    discountedPrice: 0
  }
};

export const getMedPlanNameAfterRemovingCareCounselor = (): [string, string[], string] => {
  return [
    MEDICATION_PLAN_NAME,
    [
      'Evaluation, diagnosis, and prescription by a medical prescriber',
      'Regular visits with your prescriber',
      'Monthly medication delivery (if prescribed)',
      'Access to your care team for questions about scheduling, medications, and more'
    ],
    MEDICATION_PLAN_NAME
  ];
};

export const getMedAdhocTherapyAfterRemovingCareCounselor = (): [string, string[]] => {
  return [
    'Medication + Care Management + Trial Therapy',
    [
      bullets.prescriberDiagnosis,
      bullets.developOngoing,
      bullets.medicationPrescriber,
      bullets.regularCheckins,
      bullets.regularProgress
    ]
  ];
};

export const MEDICATION_PLANS = ['medication_99', 'medication'];
export const MEDICATION_AD_HOC_THERAPY_PLANS = ['medication-first-ad-hoc-therapy', 'medication-second-ad-hoc-therapy'];

export const MEDICATION_AND_MEDICATION_ADHOC_PLANS: string[] = [
  'medication_99',
  'medication',
  'medication-first-ad-hoc-therapy',
  'medication-second-ad-hoc-therapy'
];

export const isTherapyOnlyPlan = (offeringKey: string): boolean => {
  return ['therapy', 'first-ad-hoc-therapy', 'second-ad-hoc-therapy'].includes(offeringKey);
};

const shouldShowMedCMBulletForMedicationPlanCard = (currentOfferingKey: string, offeringKey: string): boolean => {
  return (
    !!currentOfferingKey &&
    MEDICATION_AND_MEDICATION_ADHOC_PLANS.includes(offeringKey) &&
    !MEDICATION_AND_MEDICATION_ADHOC_PLANS.includes(currentOfferingKey)
  );
};

export const getBulletsByTruepillWindDown = (
  offeringKey: string,
  isSubscribedAfterRemovingCareCounselor: boolean | undefined,
  currentBullets: string[],
  currentOfferingKey = ''
): string[] => {
  return (
    [
      // if plan card is medication related and current plan is not medication related
      {
        value: newMedication99Bullets,
        condition: shouldShowMedCMBulletForMedicationPlanCard(currentOfferingKey, offeringKey)
      },
      {
        value: isSubscribedAfterRemovingCareCounselor ? newMedication99Bullets : newMedicationCCBullets,
        condition: [...MEDICATION_PLANS, ...MEDICATION_AD_HOC_THERAPY_PLANS].includes(offeringKey)
      },
      {
        value: newMedicationTherapyBullets,
        condition: offeringKey === 'medication-therapy'
      },
      {
        value: newTherapyBullets,
        condition: isTherapyOnlyPlan(offeringKey)
      }
    ].find(({ condition }) => condition)?.value ?? currentBullets
  );
};

export const isMedicationTherapyPriceIncreased = (offeringKey: string, increasePriceTherapyFlag = false): boolean => {
  return increasePriceTherapyFlag && offeringKey === 'medication-therapy';
};

export const isTherapyPriceIncreased = (offeringKey: string, increasePriceTherapyFlag = false): boolean => {
  return increasePriceTherapyFlag && offeringKey === 'therapy';
};

export const getTherapyPlanPrice = (
  offeringKey: string,
  price: string | number | undefined,
  increasePriceTherapyFlag = false,
  isInsurance?: boolean
): number => {
  if (!isInsurance && isMedicationTherapyPriceIncreased(offeringKey, increasePriceTherapyFlag)) {
    return locale.medicationTherapy365Prices.priceMonth;
  }

  if (!isInsurance && isTherapyPriceIncreased(offeringKey, increasePriceTherapyFlag)) {
    return locale.therapy295Prices.priceMonth;
  }

  return Number(price);
};

export default {
  membershipFee,
  cashPlans,
  insurancePlans,
  upSellPlans
};
