const migrations = {
  // increment this key every time you add a new property to any reducer
  // ↓ this integer key must be the same as "version" value in persistConfig object in src/store/configure_store.js
  0: state => {
    return {
      state,
      global_reducer: {
        ...state.global_reducer
      },
      patient_reducer: {
        ...state.patient_reducer,
        // when you add a new property to any reducer - just add it to a migrations tree as in the reducer's initial state
        appointments: [],
        availableDates: [],
        availableDatesLoading: false,
        availableTimeSlots: [],
        selectedTimeSlot: ''
      },
      api_middleware: {
        ...state.api_middleware
      },
      messenger_reducer: {
        ...state.messenger_reducer
      }
    };
  },
  1: state => {
    return {
      state,
      global_reducer: {
        ...state.global_reducer
      },
      patient_reducer: {
        ...state.patient_reducer,
        prescriptions: [],
        prescriptionsLoading: false
      },
      api_middleware: {
        ...state.api_middleware
      },
      messenger_reducer: {
        ...state.messenger_reducer
      }
    };
  },
  2: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        current_provider: {}
      }
    };
  },
  3: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,

        cancellation_discount_end_date: '',

        cancelSubscription: {
          loading: false,
          redirection: false,
          textOnLoading: '',
          textOnLoaded: ''
        },

        applyCoupon: {
          loading: false,
          redirecting: false,
          textOnLoading: '',
          textOnLoaded: ''
        }
      }
    };
  },
  4: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        therapist: {}
      }
    };
  },
  5: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        currentPatientPath: []
      }
    };
  },
  6: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        nextRating: {
          userId: null,
          ratingType: null,
          appointmentStartAt: null,
          carePersonFullName: null,
          carePersonId: null,
          carePersonProfilePhotoUrl: null
        }
      }
    };
  },
  7: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        abTest: {
          liveChat: ''
        }
      }
    };
  },
  8: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        insurance_visits_policy: {}
      }
    };
  },
  9: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        care_coordinator: {}
      }
    };
  },
  10: state => {
    return {
      ...state,
      global_reducer: {
        ...state.global_reducer,
        state_config: {}
      }
    };
  },
  11: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        plan: {}
      }
    };
  },
  12: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        insurance_medications_policy: {}
      }
    };
  },
  13: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        insurance_visits_policy: {},
        insurance_medications_policy: {}
      }
    };
  },
  14: state => {
    return {
      messenger_reducer: {
        ...state.messenger_reducer,
        typing_user_name: ''
      }
    };
  },
  15: state => {
    return {
      ...state,
      messenger_reducer: {
        ...state.messenger_reducer,
        active_messenger: ''
      }
    };
  },
  16: state => {
    return {
      ...state,
      messenger_reducer: {
        ...state.messenger_reducer,
        sender_id: {},
        thread_id: {},
        thread_keys: {},
        recipient_id: {},
        messages: {}
      }
    };
  },
  17: state => {
    return {
      ...state,
      messenger_reducer: {
        ...state.messenger_reducer,
        sender_id: {},
        thread_id: {},
        thread_key: {},
        recipient_id: {},
        messages: {}
      }
    };
  },
  18: state => {
    return {
      ...state,
      exercise_reducer: {
        ...state.exercise_reducer,
        categories: [],
        assignments: [],
        exercise_history: [],
        all_exercises: [],
        active_exercise: {
          answers: {}
        },
        completed_exercise: {}
      }
    };
  },
  19: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        flowKind: ''
      },
      questions_reducer: {
        toNextQuestion: true,
        loading: false,
        questionsByBank: {},
        questionBanks: [],
        currentBankIndex: 0,
        refreshBanks: false,
        currentBankName: '',
        currentQuestionName: '',
        currentQuestionIndex: 0,
        refreshQuestions: false,
        currentWidth: 0,
        step: 0,
        error: ''
      }
    };
  },
  20: state => {
    return {
      ...state,
      patient_reducer: {
        scores: {
          mdq: {}
        }
      }
    };
  },
  21: state => {
    return {
      ...state,
      patient_reducer: {
        eligibleServiceLines: {}
      }
    };
  },
  22: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        visit_object: {}
      }
    };
  },
  23: state => {
    return {
      ...state,
      patient_reducer: {
        ...state.patient_reducer,
        accountId: ''
      }
    };
  },
  24: state => {
    return {
      ...state,
      patient_reducer: {
        scores: {
          cage: {}
        }
      }
    };
  }
};

export default migrations;
