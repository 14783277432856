import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Store } from 'types/store';
import { useApi } from 'utils/api';
import { mapResponseToError } from 'utils/common';
import tracking from 'utils/tracking';
import { ServiceLine } from 'components/question_types/selector/BankSelectorV1/BankSelectorV1';
import type { ProgressiveResponse } from '../types';

export const useGetServiceLines = (): ProgressiveResponse<ServiceLine[]> => {
  const api = useApi();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const serviceLinesEndpoint = `/api/service_lines?is_follow_up=${!!search.get('is_follow_up')}`;
  const isSymptomScreen = location.pathname.includes('symptoms');
  const user = useSelector((store: Store) => store.global_reducer.current_user);

  const { data, isLoading, isSuccess, status } = useQuery(
    ['serviceLinesData', user.attributes.patient?.id],
    () => api.get<ServiceLine[]>(serviceLinesEndpoint),
    {
      enabled: !!api && !isSymptomScreen,
      onError: err => {
        const errorMessage = mapResponseToError(err);
        tracking.error({ e: err, message: errorMessage });
      }
    }
  );

  return useMemo(() => ({ isLoading, isSuccess, data: data?.data || [], status }), [data, isLoading]);
};
