import React, { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

type Config = Record<string, number | string | boolean> & { REACT_APP_PERSONA_ENVIRONMENT?: 'sandbox' | 'production' };

const ConfigContext = createContext<Config>({});

// this should eventually be useConfig, getting rid of the provider
const getConfig = (setter: React.Dispatch<Config>, handler: () => void) => {
  fetch(`${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL ?? ''}/config.json`)
    .then(response => (response.ok ? response.json() : new Error(response.toString())))
    .then(setter)
    .catch(handler);
};

export const ConfigProvider = ({
  children,
  defaultConfig = {}
}: {
  children: ReactNode;
  defaultConfig?: Config;
}): JSX.Element => {
  const [config, setConfig] = useState<Config>(defaultConfig ?? {});
  useEffect(() => {
    let isCanceled = false;
    getConfig(
      nextConfig => {
        if (!isCanceled) {
          setConfig(prevConfig => ({ ...prevConfig, ...nextConfig }));
        }
      },
      () => {}
    );
    return () => {
      isCanceled = true;
    };
  }, []);
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useConfig = (): Config => useContext(ConfigContext);
