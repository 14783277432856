import React, { useState, useEffect, ReactNode } from 'react';
import { NB, ChoicePill, theme } from '@cerebral-inc/ui';
import { Question } from 'types/question';
import segment from 'utils/segment';
import xor from 'lodash/xor';
import { getSegmentEvent, getSubmitSegmentEvent } from '../../helpers';
import { iterableIntegrations, segmentIntegrations } from '../../utils';
import { OnboardingButton } from '../OnboardingButton';
import { AltOnboardingHeight } from '../constants/constants';

const { Center, Text, Heading, Flex, Box, Button } = NB;

export interface IMultiSelectorSingleStackProps {
  question: Question;
  submitAnswer: (answer: string, question: Question, skip?: number) => void;
  isSingleSelect?: boolean;
  popUpText?: string;
  popUpComponent?({ isOpen, onClose }: { isOpen: boolean; onClose(): void }): ReactNode;
}

export const MultiSelectorSingleStack = ({
  question,
  submitAnswer,
  isSingleSelect,
  popUpText,
  popUpComponent
}: IMultiSelectorSingleStackProps) => {
  const [selections, setSelections] = useState<string[]>([]);
  const { title, options, subscript, name } = question;

  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const segmentName = getSegmentEvent(name);
    if (segmentName) segment.track(segmentName, undefined, segmentIntegrations);
  }, []);

  const handleSelectionClick = (clickedSelection: string) => {
    if (isSingleSelect) {
      setSelections([clickedSelection]);
      return;
    }

    if (clickedSelection === 'None') {
      setSelections(selections.includes('None') ? [] : ['None']);
      return;
    }

    const newSelections = selections.filter(item => item !== 'None');
    setSelections(xor(newSelections, [clickedSelection]));
  };

  const handleSubmit = () => {
    const finalSelections = selections.join('|');
    submitAnswer(finalSelections, question);
    const eventName = getSubmitSegmentEvent(name);
    if (eventName) segment.track(eventName, { answered: finalSelections }, iterableIntegrations);
  };

  return (
    <Center>
      <Center w={{ base: '100%', md: '488px' }}>
        <Flex
          minH={{ base: AltOnboardingHeight, md: 'auto' }}
          px={{ base: '16px', md: '0' }}
          w={{ base: '100%', md: '488px' }}
        >
          <Center>
            <Heading
              fontSize={{ base: '20px', sm: '22px' }}
              fontWeight="600"
              lineHeight="24px"
              marginBottom="1"
              maxWidth="400px"
              textAlign="center"
              fontFamily="Poppins"
              color="#1c1e21"
            >
              {title}
            </Heading>
            {subscript && (
              <Text
                textAlign="center"
                fontFamily="Poppins"
                fontWeight="400"
                marginBottom="2"
                lineHeight="24px"
                color="#353A41"
              >
                {subscript}
              </Text>
            )}
            {!isSingleSelect ? (
              <Text
                fontSize="12px"
                fontStyle="italic"
                marginBottom="5"
                fontFamily="Poppins"
                fontWeight="400"
                color="#626874"
                lineHeight="16px"
              >
                Select all that apply
              </Text>
            ) : null}
          </Center>
          <Flex direction="column" justify="center" maxWidth="600px" width="full" marginBottom="2">
            {options.map(option => (
              <Box margin="1">
                <ChoicePill
                  key={option.name}
                  onSelect={() => handleSelectionClick(option.name)}
                  choicePillKey={option.name}
                  choicePillType="multiple"
                  selected={selections.includes(option.name)}
                  labelText={option.name}
                  fullWidth
                />
              </Box>
            ))}
          </Flex>
          {popUpText ? (
            <Center>
              <Button
                onPress={() => {
                  setOpenPopup(true);
                }}
                variant="teritary"
              >
                <Text
                  color="c_primary.600"
                  textDecorationLine="underline"
                  fontSize={theme.fontSizes.xs}
                  fontWeight={theme.fontWeights.semibold}
                >
                  {popUpText}
                </Text>
              </Button>
              {openPopup && popUpComponent
                ? popUpComponent({ isOpen: openPopup, onClose: () => setOpenPopup(false) })
                : null}
            </Center>
          ) : null}
          <Box maxWidth="md" width="full" mt="3" />
        </Flex>
        <OnboardingButton isDisabled={selections.length === 0} text="Continue" onSubmit={handleSubmit} isStickyButton />
      </Center>
    </Center>
  );
};
