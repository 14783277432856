import React from 'react';
import { NB } from '@cerebral-inc/ui';
import { PRE_SIGNUP_BASE_PATH } from 'components/Onboarding/PreSignupFlow/QuestionManager/constant';

export interface OnboardingButtonProps {
  onSubmit: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  marginTop?: string;
  mobileMarginTop?: string;
  text: string;
  variant?: string;
  isStickyButton?: boolean;
  noBorder?: boolean;
  desktopWidth?: string;
}

const { Button, Center, useMediaQuery } = NB;

export const OnboardingButton = ({
  onSubmit,
  isLoading,
  isDisabled,
  marginTop,
  mobileMarginTop,
  text,
  variant = 'primary',
  isStickyButton = false,
  noBorder = false,
  desktopWidth = '400px'
}: OnboardingButtonProps): JSX.Element => {
  const [isMobile] = useMediaQuery({ minWidth: 0, maxWidth: 767 });
  const isStickyButtonOnMobile = isMobile && isStickyButton;
  const isPreSignUp = window.location.pathname.includes(PRE_SIGNUP_BASE_PATH);

  const generateButton = () => {
    return (
      <Button
        variant={variant}
        onPress={onSubmit}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        w={{ base: '100%', sm: desktopWidth }}
        maxW="100%"
        h="56px"
        _text={{
          fontFamily: 'Poppins',
          fontSize: '14px'
        }}
        _disabled={{
          opacity: 1
        }}
        id="onboardingButton"
      >
        {text}
      </Button>
    );
  };

  return isStickyButtonOnMobile ? (
    <>
      <Center
        position="sticky"
        bottom={0}
        w="100vw"
        px="24px"
        py="16px"
        backgroundColor="c_white.900"
        borderTopColor="#d9d9d9"
        borderTopStyle="solid"
        borderTopWidth={noBorder ? 0 : '1px'}
        mt={isPreSignUp ? '24px' : mobileMarginTop || {}}
      >
        {generateButton()}
      </Center>
    </>
  ) : (
    <Center w="100%" mt={marginTop}>
      {generateButton()}
    </Center>
  );
};
