import React from 'react';

interface PaymentTextUS {
  buttonText: string;
  monthlyPrice?: number;
  price?: number;
  isInsurancePostCheckout?: boolean;
  isInsurancePayerV2?: boolean;
  changePlanScreen?: boolean;
  isAdHocTherapyPlan?: boolean;
}

interface PaymentText {
  buttonText: string;
}

interface PaymentNonTherapyTextUS {
  buttonText: string;
  price?: number;
  monthlyPrice?: number;
}

interface TermComponentProps {
  changePlanScreen: boolean;
  monthlyPrice?: number;
  price?: number;
}

const TermComponent = (props: TermComponentProps) => {
  const { changePlanScreen, monthlyPrice, price } = props;
  if (changePlanScreen) {
    return (
      <>
        . You also authorize us to charge you the plan price difference if you are upgrading to a more expensive plan
        today and then ${monthlyPrice} automatically each month thereafter until you cancel. Cancel by clicking “Cancel
        Subscription” in your Cerebral account before your next billing date to avoid future charges. Once charged, all
        purchases are non-refundable (including no refunds for partial subscription periods) except as provided in our{' '}
      </>
    );
  }
  return (
    <>
      . You also authorize us to charge you ${price} today and then ${monthlyPrice} automatically each month thereafter
      until you cancel. Cancel by clicking “Cancel Subscription” in your Cerebral account before your next billing date
      to avoid future charges. Once charged, all purchases are non-refundable (including no refunds for partial
      subscription periods) except as provided in our{' '}
    </>
  );
};

export const USPaymentTerm = ({
  buttonText,
  monthlyPrice,
  price,
  isInsurancePostCheckout,
  isInsurancePayerV2,
  changePlanScreen = false,
  isAdHocTherapyPlan = false
}: PaymentTextUS) => {
  const customButtonText = isInsurancePostCheckout ? `“${buttonText},”` : `“${buttonText}”,`;

  return (
    <>
      By clicking {customButtonText} you agree to Cerebral’s{' '}
      <a href="https://getcerebral.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
        Terms of Use
      </a>{' '}
      and{' '}
      <a href="https://getcerebral.com/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      {isInsurancePayerV2 ? (
        '. You authorize us to charge you based on your copay, coinsurance, and deductibles through your insurance plan after each visit.'
      ) : (
        <>
          {!changePlanScreen && isAdHocTherapyPlan ? (
            <>
              . You also authorize us to charge you ${price} today. Once charged, all purchases are non-refundable
              (including no refunds for partial subscription periods) except as provided in our{' '}
            </>
          ) : (
            <TermComponent changePlanScreen={changePlanScreen} monthlyPrice={monthlyPrice} price={price} />
          )}

          <a href="https://getcerebral.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
            Terms of Use.
          </a>
        </>
      )}
    </>
  );
};

export const USPaymentNonTherapyTerm: React.FC<PaymentNonTherapyTextUS> = ({ buttonText, price, monthlyPrice }) => {
  return (
    <>
      By clicking “{buttonText},” you agree to Cerebral’s{' '}
      <a href="https://getcerebral.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
        Terms of Use
      </a>{' '}
      and{' '}
      <a href="https://getcerebral.com/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      . You also authorize us to charge you ${price} today and then ${monthlyPrice} automatically each month thereafter
      until you cancel. This monthly subscription fee is your responsibility, and is not covered by insurance, even if
      Cerebral is in-network with your health insurance plan. This monthly fee allows us to offer concierge value-added
      services to clients that are not covered in our provider agreements. Cancel by clicking “Cancel Subscription” in
      your Cerebral account before your next billing date to avoid future charges. Once charged, all purchases are
      non-refundable (including no refunds for partial subscription periods) except as provided in our{' '}
      <a href="https://getcerebral.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
        Terms of Use.
      </a>
      <>
        <br />
        <br />
        <i>
          Visit co-pays and medication costs are billed separately from your monthly subscription fee - these costs will
          depend on your specific insurance.
        </i>
      </>
    </>
  );
};

export const USSignupTerm: React.FC<PaymentText> = ({ buttonText }) => (
  <>
    By clicking “{buttonText}”, you agree to Cerebral’s{' '}
    <a href="https://getcerebral.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
      Terms & Conditions
    </a>{' '}
    , including the mandatory arbitration provision,{' '}
    <a href="https://getcerebral.com/privacy-policy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>{' '}
    and{' '}
    <a href="https://getcerebral.com/telehealth-informed-consent" target="_blank" rel="noopener noreferrer">
      Telehealth Consent.
    </a>
    .
  </>
);

export const UKTerm: React.FC<PaymentText> = ({ buttonText }) => (
  <>
    By clicking “{buttonText}” you consent to Cerebral’s{' '}
    <a href="https://getcerebral.co.uk/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
      Terms and Conditions of Service
    </a>{' '}
    which includes policies on cancellation, recurring billing and payment terms.
    <br />
    If your GP does decide that medication is appropriate for your condition, this is included in your monthly
    subscription at no extra charge.
  </>
);
