import React, { useEffect, ReactNode } from 'react';
import { useConfig } from 'contexts/ConfigContext';
import { datadogLogs } from '@datadog/browser-logs';
import { MaintenancePage } from '../MaintenancePage';

export const MaintenanceBoundary = ({ children }: { children: ReactNode }): JSX.Element => {
  const config = useConfig();

  useEffect(() => {
    if (config.maintenanceMode) {
      datadogLogs.logger.info('[Maintenance] mode viewed');
    }
  }, [config.maintenanceMode]);

  return config.maintenanceMode ? <MaintenancePage /> : <>{children}</>;
};
