import Axios, { AxiosRequestConfig } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axiosInstance from '../utils/api-client';

export type ApiTypes = {
  BASE: string;
  BEGIN: string;
  SUCCESS: string;
  FAILED: string;
};

export type SucessAction = Action & {
  data: unknown;
};

export type FailedAction = Action & {
  error: string;
};

export type ApiActions<R> = {
  request: AxiosRequestConfig;
  begin: () => Action;
  success: (response: R) => SucessAction;
  failed: (error: string) => FailedAction;
};

export type ThunkResult<R> = ThunkAction<R, undefined, undefined, Action>;

export const createApiTypes = (type: string): ApiTypes => ({
  BASE: type,
  BEGIN: `${type}_BEGIN`,
  SUCCESS: `${type}_SUCCESS`,
  FAILED: `${type}_FAILED`
});

export function createApiActions<R>(type: ApiTypes, request: AxiosRequestConfig): ApiActions<R> {
  return {
    request,
    begin: () => ({
      type: type.BEGIN
    }),
    success: (data: R) => ({
      data,
      type: type.SUCCESS
    }),
    failed: (error: string) => ({
      error,
      type: type.FAILED
    })
  };
}

export function sendRequest<R>(apiActions: ApiActions<R>, failedDelay = 0): ThunkResult<void> {
  return async dispatch => {
    // used to measure elapsed time to indicate to user that a request was made
    const start = new Date();
    dispatch(apiActions.begin());
    try {
      const response = await axiosInstance.request<R>(apiActions.request);
      dispatch(apiActions.success(response.data));
    } catch (e) {
      let error = 'Unknown error';
      if (Axios.isAxiosError(e)) {
        if (e.response) {
          error = e.response.statusText || String(e.response.status);
        } else {
          error = 'No response received from server';
        }
      }
      // show loading indicator for at least 500ms
      const elapsed = new Date().getTime() - start.getTime();
      if (elapsed < failedDelay) {
        setTimeout(() => {
          dispatch(apiActions.failed(error));
        }, failedDelay - elapsed);
      } else {
        dispatch(apiActions.failed(error));
      }
    }
  };
}
