import React from 'react';
import { NB } from '@cerebral-inc/ui';
import { OnboardingButton } from 'components/Onboarding/shared';
import { useSelector } from 'react-redux';
import { Store } from 'types/store';
import { useFeatureFlags } from 'features';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { RTEStatusEnum } from 'constants/status.enum';
import { useRedirectToAuth0 } from 'components/UniversalLogin/useRedirectToAuth0';
import { isFromMobile, toMobileCallbackUrl, toWebCallbackUrl } from 'types/universal-login.utils';
import { useRTEConfigs } from 'features/useRTEConfigs';
import { isInNetworkState } from 'utils/common';
import Spinner, { SpinnerHolder } from 'components/Spinner';
import { InterstitialProps } from '../types';
import AssessmentIcon from '../MedsQualifiedPrescribers/assets/assessment.svg';
import { copy } from './constants';

const { Center, Flex, Text, Image, VStack } = NB;

export const Progress = ({
  nextQuestion,
  step: currentStep
}: Omit<InterstitialProps, 'offeringKey'> & { step: 1 | 2 | 3 | 4 }) => {
  const history = useHistory();
  const user = useSelector(({ global_reducer: { current_user } }: Store) => current_user);
  const userRegion = user.attributes.patient?.region || '';
  const { data: flags, isLoading: isFlagsLoading } = useFeatureFlags({ user_id: user.attributes.id });
  const { data: rteConfigs, isLoading: isRteConfigsLoading } = useRTEConfigs({ state: userRegion });
  const redirectToAuth0 = useRedirectToAuth0();

  const webOrMobileCallback = isFromMobile() ? toMobileCallbackUrl : toWebCallbackUrl;

  const handleContinue = async () => {
    const obFlow = Cookies.get('ob_flow');

    if (currentStep === 1 && sessionStorage.getItem('anonymous_id')) {
      redirectToAuth0({ returnTo: webOrMobileCallback(), screen_hint: 'signup' });
      return;
    }
    if (
      currentStep === 2 &&
      (obFlow === 'insurance_eligibility_check' || user.attributes.patient?.rte_status === RTEStatusEnum.PRE_RTE)
    ) {
      const isEnabledRteState = flags.insurance_rte_release_state_provider_config
        ? userRegion && rteConfigs.global_rte_enabled && rteConfigs.states?.[0]?.rte_enabled
        : isInNetworkState(userRegion);

      if (flags.insurance_rte_release_onboarding && isEnabledRteState) {
        history.push('/patient/insurance-coverage');
      } else {
        history.push('/patient/insurance-coverage/unable-verify');
      }
    } else {
      nextQuestion();
    }
  };

  if (currentStep === 2 && isFlagsLoading && isRteConfigsLoading) {
    return (
      <SpinnerHolder>
        <Spinner />
      </SpinnerHolder>
    );
  }

  return (
    <Center>
      <Flex
        textAlign="center"
        minH={{ base: 'calc(100dvh - 180px)', md: 'auto' }}
        alignItems="center"
        w={{ base: '325px', md: '480px' }}
      >
        <Image src={AssessmentIcon} alt="Assessment Icon" w="40px" h="40px" />
        <Text
          fontSize={{ base: '20px', md: '22px' }}
          fontWeight="semibold"
          lineHeight="32px"
          color="c_black.100"
          mt="8px"
        >
          {copy.title[currentStep - 1]}
        </Text>

        <VStack space="12px" w="100%" mt="24px" mb="40px">
          {copy.steps.map((step, i) => {
            const isActiveStep = currentStep === i + 1;
            return (
              <Flex
                key={step}
                flexDirection="row"
                paddingY="16px"
                paddingX="12px"
                borderWidth="2px"
                borderStyle="solid"
                borderRadius="8px"
                alignItems="center"
                borderColor={isActiveStep ? '#EEECF5' : 'transparent'}
                backgroundColor={isActiveStep ? 'c_secondary.50' : 'transparent'}
              >
                <Flex
                  padding="8px"
                  borderRadius="8px"
                  backgroundColor={isActiveStep ? 'c_secondary.200' : 'c_grey.50'}
                  w="40px"
                  h="40px"
                  alignItems="center"
                  justifyContent="center"
                  mr="12px"
                >
                  <Text
                    fontSize="20px"
                    lineHeight="24px"
                    color={isActiveStep ? 'c_secondary.900' : 'c_grey.800'}
                    fontWeight="semibold"
                  >
                    {i + 1}
                  </Text>
                </Flex>
                <Flex alignItems="start">
                  <Text fontSize="14px" lineHeight="20px" color="c_black.900" fontWeight="semibold">
                    {step}
                  </Text>
                </Flex>
              </Flex>
            );
          })}
        </VStack>
      </Flex>
      <OnboardingButton text="Continue" onSubmit={handleContinue} isStickyButton />
    </Center>
  );
};
