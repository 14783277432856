import { ReactElement, useEffect } from 'react';
import tracking from 'utils/tracking';
import mapErrorToMessage from './mapErrorToMessage';

type GlobalErrorCatcherProps = {
  children: ReactElement;
};

const GLOBAL_ERROR_CATCHER_TYPE = 'GLOBAL_ERROR_CATCHER';

const GlobalErrorCatcher = ({ children }: GlobalErrorCatcherProps) => {
  useEffect(() => {
    const rejectionHandler = (event: PromiseRejectionEvent) => {
      tracking.error({
        e: event?.reason,
        message: mapErrorToMessage(event?.reason),
        info: {
          type: [GLOBAL_ERROR_CATCHER_TYPE, 'REJECTION_HANDLER']
        }
      });
      // Temporary test to see if we can get more info from the event
      tracking.warningV2('Unhandled promise rejection', { reason: event?.reason, type: GLOBAL_ERROR_CATCHER_TYPE });
    };

    const errorHandler = (event: ErrorEvent) => {
      tracking.error({
        e: event?.error,
        message: mapErrorToMessage(event),
        info: {
          event,
          fileName: event?.filename,
          lineNumber: event?.lineno,
          columnNumber: event?.colno,
          message: event?.message,
          error: event?.error,
          type: [GLOBAL_ERROR_CATCHER_TYPE, 'ERROR_HANDLER']
        }
      });
      // Temporary test to see if we can get more info from the event
      tracking.warningV2('Unhandled error', {
        error: event?.error,
        event,
        type: GLOBAL_ERROR_CATCHER_TYPE,
        fileName: event?.filename,
        lineNumber: event?.lineno,
        columnNumber: event?.colno,
        message: event?.message
      });
    };

    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', rejectionHandler);

    return () => {
      window.removeEventListener('unhandledrejection', rejectionHandler);
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  return children;
};

export default GlobalErrorCatcher;
