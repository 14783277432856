import { handleActions } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';
import { SET_QUESTION_BANKS } from 'actions/patientActions/setQuestionBanks';
import { SET_VISIT } from 'actions/patientActions/setVisit';
import * as patient_action_types from '../actions/patient_action';
import { getNumberOfQuestions, calculateStep, getNextBankOffset, bankToPath, toBankNames } from '../utils/common';
import { setBranchQuestions, mapTitleToIndex, populate, updateTitles } from '../utils/reducersHelpers';

const isAdHocTherapy = oldOfferingKey => {
  return [
    'first-ad-hoc-therapy',
    'second-ad-hoc-therapy',
    'medication-first-ad-hoc-therapy',
    'medication-second-ad-hoc-therapy'
  ].includes(oldOfferingKey);
};

const fromVisitToQuestionBanks = (visit, oldOfferingKey) => {
  const { has_submitted_shipping, has_assigned_therapist, needs_initial_provider_appointment, has_verified_identity } =
    visit;
  const banks = [];

  if (has_submitted_shipping) {
    banks.push('shipping_address');
  }

  if (has_verified_identity) {
    banks.push('information_identity');
  }

  if (has_assigned_therapist && !isAdHocTherapy(oldOfferingKey)) {
    banks.push('choose_therapist');
  }

  if (!needs_initial_provider_appointment) {
    banks.push('visit_with_provider');
  }

  return banks;
};

export const init_patient_state = {
  service_line: '',
  key: '',
  flowKind: '',

  // this is a cache of all of the question bank information, used so we can draw the correct
  // title for the question bank at the top of screen
  question_bank_objects: null,

  // all of the question_bank names for the current user
  question_banks: [],
  currentPatientPath: [],
  // which question bank the user is currently looking at
  question_banks_step: 0,

  // equal to questions_banks[question_bank_step].id
  question_bank_id: '',

  // question bank name that for current questions
  current_bank_name: '',

  // questions from current question_bank_id
  questions: '',
  step: 0,
  total_step: 1,

  // branch question
  branch_questions: [],
  branch_question_bank: '',
  branch_question_step: 0,
  branch_question_active: false,

  global_progressbar_width: 0,

  // contains a copy of patient/visit information from the database
  patient_object: null,
  migration_status: null,
  lastest_migration_status: null,
  visit_object: {
    is_follow_up: false
  },
  reactivation_visit: false,
  // TODO: get a real care manager later
  current_provider: {},
  care_manager: {},
  care_coordinator: {},
  therapist: {},
  insurance_visits_policy: {},
  insurance_medications_policy: {},
  plan: {},
  treatment_object: null,
  dosage_object: null,
  is_valid_state: false,
  eligibleServiceLines: {},
  is_medicare_secondary: false,
  payerOnboarding: {},
  payer_not_in_listed: '',
  medication_waitlist: {},
  provider_ini: {},
  // score for assessment questions
  scores: {
    asrs: {},
    phq9: {},
    gad7: {},
    isi: {},
    mdq: {},
    smoking: {},
    alcohol: {},
    nutrition: {},
    cage: {}
  },

  // patient's outstanding tasks
  outstanding_tasks: [],

  // patient's visits
  visits: [],

  // calendar
  appointments: [],
  availableDates: [],
  availableDatesLoading: false,
  availableTimeSlots: [],
  selectedTimeSlot: '',

  // prescriptions
  prescriptions: [],
  prescriptionsLoading: false,

  cancellation_discount_end_date: '',

  cancelSubscription: {
    loading: false,
    redirection: false,
    textOnLoading: '',
    textOnLoaded: ''
  },

  applyCoupon: {
    loading: false,
    redirecting: false,
    textOnLoading: '',
    textOnLoaded: ''
  },

  nextRating: {
    ratingType: null,
    userId: null,
    appointmentStartAt: null,
    carePersonFullName: null,
    carePersonId: null,
    carePersonProfilePhotoUrl: null
  },

  abTest: {
    liveChat: ''
  },

  direct_messaging_pilot: false,
  offering_key: '',
  region: '',
  isEligible: false,
  newAppointmentModalClosed: false,
  accountId: '',
  service_line_not_available: '',
  isOnlineCheckin: false,
  postCheckOutFlowVariation: 'excluded',

  paymentSubscription: '',
  cancellationAppointments: [],
  insuranceInformation: null,
  ilvStatus: '',
  ilvPrescriber: null,
  subscribedAfterRemovingCareCounselor: false,
  reassignmentReason: { started: false },
  preferredPlan: null,
  impactToken: null,
  shouldShowUpsell: true
};

export const patient_reducer = handleActions(
  {
    [patient_action_types.SET_PROVIDER_INI]: (state, action) => {
      return {
        ...state,
        provider_ini: {
          has_prescriber: action.payload.has_prescriber,
          has_therapist: action.payload.has_therapist
        }
      };
    },
    [patient_action_types.SET_ELIGIBLE]: (state, action) => {
      const { isEligible } = action;

      return {
        ...state,
        isEligible
      };
    },
    [patient_action_types.SET_FLOW_KIND]: (state, action) => {
      const { flowKind } = action;

      return {
        ...state,
        flowKind
      };
    },
    [patient_action_types.SET_INSURANCE_VISITS_POLICY]: (state, action) => {
      const { policy } = action;

      if (policy) {
        return {
          ...state,
          insurance_visits_policy: { ...policy }
        };
      }
      return state;
    },
    [patient_action_types.SET_CAPACITY_CHECK]: (state, action) => {
      const { capacity } = action;

      if (capacity !== undefined) {
        return {
          ...state,
          capacity: action.capacity
        };
      }
      return state;
    },
    [patient_action_types.SET_INSURANCE_MEDICATIONS_POLICY]: (state, action) => {
      const { policy } = action;

      if (policy) {
        return {
          ...state,
          insurance_medications_policy: { ...policy }
        };
      }
      return state;
    },
    [patient_action_types.SET_INSURANCE_PHOTOS]: (state, action) => ({
      ...state,
      insurance_cards: action.photos
    }),
    [patient_action_types.SET_SECOND_INSURANCE_PHOTOS]: (state, action) => ({
      ...state,
      second_insurance_cards: action.photos
    }),
    [patient_action_types.SET_IS_MEDICARE_SECONDARY]: (state, action) => ({
      ...state,
      is_medicare_secondary: action.isMedicareSecondary ? action.isMedicareSecondary : false
    }),
    [patient_action_types.SET_PAYER_ONBOARDING]: (state, action) => ({
      ...state,
      payerOnboarding: action.payer
    }),
    [patient_action_types.SET_MIGRATION_STATUS]: (state, action) => ({
      ...state,
      migration_status: action.migrationStatus
    }),
    [patient_action_types.SET_LASTEST_MIGRATION_STATUS]: (state, action) => ({
      ...state,
      lastest_migration_status: action.status
    }),
    [patient_action_types.SET_PAYER_NOT_IN_LISTED]: (state, action) => ({
      ...state,
      payer_not_in_listed: action.payer
    }),
    [patient_action_types.SET_MEDICATION_PHOTOS]: (state, action) => ({
      ...state,
      pharmacy_cards: action.photos
    }),
    [patient_action_types.SET_INSURANCE_INFORMATION]: (state, action) => {
      return {
        ...state,
        insuranceInformation: action.data
      };
    },
    [patient_action_types.SET_ABTEST]: (state, action) => {
      const { abTestType: type, value } = action;
      return {
        ...state,
        abTest: {
          ...state.abTest,
          [type]: value
        }
      };
    },
    [patient_action_types.SET_COUPON]: (state, action) => {
      const { coupon, price, offering } = action;
      return {
        ...state,
        coupon: {
          coupon,
          price,
          offering
        }
      };
    },
    [patient_action_types.SET_BRANCH_QUESTION_ACTIVE]: (state, action) => {
      return {
        ...state,
        branch_question_active: action.is_active
      };
    },
    [patient_action_types.OUTSTANDING_TASK_MODAL_CLOSED]: state => {
      return {
        ...state,
        outstandingTaskModalClosed: true
      };
    },
    [patient_action_types.NEW_APPOINTMENT_MODAL_CLOSED]: state => {
      return {
        ...state,
        newAppointmentModalClosed: true
      };
    },
    [patient_action_types.SET_BRANCH_QUESTION_STEP]: (state, action) => {
      return {
        ...state,
        branch_question_step: action.step
      };
    },
    [patient_action_types.SET_VISIT_IS_COMPLETE]: (state, action) => {
      return {
        ...state,
        visit_object: {
          ...state.visit_object,
          complete: action.isComplete,
          pending_updates: action.pendingUpdate
        }
      };
    },
    [patient_action_types.SET_CURRENT_PATIENT_PATH]: (state, action) => {
      const {
        question_banks,
        visit_object,
        insurance_visits_policy,
        insurance_medications_policy,
        plan,
        pending_plan_change = {},
        direct_messaging_pilot,
        region,
        isMeditationUpgrade
      } = state;
      const { last_answer, is_follow_up, pending_updates, service_lines } = visit_object || {};
      const { offering_key, is_insurance } = plan;
      const { isPaymentSubmitted, removeUpdatePlan, communicationPreferences } = action;
      const serviceLineNames = service_lines.map(line => line.name);
      // get path
      let flowType = is_follow_up ? 'follow-up-assessment' : offering_key;

      if (isMeditationUpgrade) {
        flowType = 'upsell-from-meditation';
      }

      let path = toBankNames(
        pending_updates,
        pending_plan_change,
        flowType,
        is_insurance,
        direct_messaging_pilot,
        region
      );
      // cut all the banks that come before checkout
      if (isPaymentSubmitted && !isMeditationUpgrade) {
        const idx = path.indexOf('checkout');
        path = path.slice(idx < 0 ? 0 : idx);
      }

      if (isPaymentSubmitted && removeUpdatePlan) {
        path = path.filter(name => name !== 'update_plan');
      }

      if (insurance_visits_policy?.payer?.name.toLowerCase().includes('medicare')) {
        path = path.filter(name => name !== 'second_medicare');
      }

      if (isPaymentSubmitted && removeUpdatePlan) {
        path = path.filter(name => name !== 'smoking_update_plan');
      }

      // do not show oon_insurance if insurance policy is present with or without RxBin
      if (isPaymentSubmitted && !isEmpty(insurance_visits_policy)) {
        path = path.filter(name => name !== 'oon_insurance');
      }

      // do not show medication_insurance if medication insurance is present or RxBin number is missing
      if (isPaymentSubmitted && !isEmpty(insurance_medications_policy)) {
        path = path.filter(name => name !== 'medication_insurance');
      }

      // don't show choose pharmacy if patient didn't adhd service line
      if (!serviceLineNames.includes('adhd') && !serviceLineNames.includes('smoking')) {
        path = path.filter(name => name !== 'choose_pharmacy');
      }

      // do not show communication preferences if comm preferences are set or service lines include adhd or bipolar
      if (communicationPreferences || serviceLineNames.some(name => name === 'adhd' || name === 'bipolar')) {
        path = path.filter(name => name !== 'communication');
      }

      let idx = 0;
      if (!isEmpty(last_answer)) {
        const { response, question_name, question_bank_name } = last_answer;
        const offset = getNextBankOffset(question_name, response);
        idx = Math.max(path.indexOf(question_bank_name) + offset, idx);

        if (question_bank_name === 'communication' && idx > 0) {
          idx -= 1;
        }
      }

      // for the shipping address and therapist appointment
      const banks = fromVisitToQuestionBanks(visit_object, pending_plan_change.old_offering_key);
      const highestIndex = banks.reduce((index, item) => {
        const currentIdx = path.indexOf(item);
        return Math.max(currentIdx + 1, index);
      }, idx);

      const incompleted = path.slice(highestIndex);

      if (
        offering_key === 'mat-counseling' &&
        incompleted.indexOf('visit_type') === -1 &&
        incompleted.indexOf('visit_with_provider') === -1
      ) {
        incompleted.push('visit_type');
        incompleted.push('visit_with_provider');
      }

      const currentPatientPath = question_banks.reduce(bankToPath(incompleted), []).filter(Boolean);

      // check if payment is submitted
      let number = isPaymentSubmitted ? 40 : 60;

      if (is_follow_up) {
        number = 100;
      }

      // number of questions before or after checkout
      const num_of_questions = getNumberOfQuestions(currentPatientPath, isPaymentSubmitted, is_follow_up);
      const step = calculateStep(number, num_of_questions);

      return {
        ...state,
        currentPatientPath,
        step
      };
    },
    [patient_action_types.REMOVE_QUESTION_BANK]: (state, action) => {
      const { name } = action;
      const { currentPatientPath } = state;
      const number = 60;
      const isPaymentSubmitted = true;
      const isFollowUp = false;

      const newCurrentPatientPath = currentPatientPath.filter(bank => bank.name !== name);
      const num_of_questions = getNumberOfQuestions(newCurrentPatientPath, isPaymentSubmitted, isFollowUp);
      const step = calculateStep(number, num_of_questions);

      return {
        ...state,
        step,
        currentPatientPath: newCurrentPatientPath
      };
    },
    [patient_action_types.SET_VISIT_TYPE]: (state, action) => {
      return {
        ...state,
        visit_object: {
          ...state.visit_object,
          visit_type: action.visitType
        }
      };
    },
    [patient_action_types.SET_PENDING_UPDATE]: (state, action) => {
      return {
        ...state,
        visit_object: {
          ...state.visit_object,
          pending_updates: action.flag
        }
      };
    },
    [patient_action_types.SET_IS_PAYMENT_SUBMITTED]: (state, action) => {
      const {
        currentPatientPath,
        visit_object: { is_follow_up }
      } = state;
      const { is_payment_submitted } = action;

      const num_of_questions = getNumberOfQuestions(currentPatientPath, is_payment_submitted, is_follow_up);
      const number = is_payment_submitted ? 40 : 60;

      const step = calculateStep(number, num_of_questions);

      return {
        ...state,
        step
      };
    },
    [patient_action_types.SET_STEP]: (state, action) => {
      return {
        ...state,
        step: action.step,
        is_complete: action.is_complete
      };
    },
    // remove branch_questions and branch_question_bank in the future because
    // of question_bank_object has both
    [patient_action_types.SET_BRANCH_QUESTION]: (state, action) => {
      const getQuestions = setBranchQuestions(action.flow_type);
      const questions = getQuestions(state, action);
      let branch_ques_step = 0;

      if (['checkout', 'visit_type', 'visit_with_provider'].includes(action.bank_name)) {
        // eslint-disable-next-line prefer-destructuring
        branch_ques_step = state.branch_question_step;
      }
      return {
        ...state,
        branch_questions: questions,
        branch_question_bank: action.bank_name,
        branch_question_step: branch_ques_step,
        branch_question_active: true,
        question_bank_objects: {
          ...state.question_bank_objects,
          [action.bank_name]: questions
        }
      };
    },

    // get questions for specific branch,
    // insert additional and update current bank
    // update branch_question_step
    [patient_action_types.ADD_ADDITIONAL_QUESTIONS_TO_CURRENT_BANK]: (state, action) => {
      const {
        visit_object: { service_lines }
      } = state;
      const updatedQuestions = updateTitles(action.bank_name, action.questions, {
        serviceLines: [...service_lines],
        region: action.region
      });

      const questions = populate(state.question_bank_objects[action.bank_name], action.idx, updatedQuestions);

      return {
        ...state,
        question_bank_objects: {
          ...state.question_bank_objects,
          [action.bank_name]: questions
        }
      };
    },
    [patient_action_types.SET_BANK_STEP_AND_QUESTION_STEP]: (state, action) => {
      return {
        ...state,
        question_banks_step: action.bank_index,
        branch_question_step: action.question_index,
        key: action.key
      };
    },
    [patient_action_types.RESET_QUESTIONS_AND_VISIT]: state => {
      const { visit_object } = state;
      return {
        ...state,
        currentPatientPath: [],
        questions: '',
        step: 0,
        question_bank_objects: {},
        question_banks: [],
        question_banks_step: 0,
        branch_questions: [],
        branch_question_step: 0,
        global_progressbar_width: 0,
        current_bank_name: '',
        branch_question_bank: '',
        visit_object: {
          is_follow_up: false,
          complete: visit_object?.complete,
          visit_type: visit_object?.visit_type
        },
        treatment_object: null,
        dosage_object: null
      };
    },
    [patient_action_types.SET_PATIENT]: (state, action) => {
      return {
        ...state,
        patient_object: action.patient_object
      };
    },
    [SET_VISIT]: (state, action) => {
      // will change when BE is ready
      const visit_object = { ...action.visit_object };
      visit_object.is_follow_up = !visit_object?.is_initial && !visit_object?.is_reactivation;

      const service_lines = Array(11).fill(null);

      action.visit_object?.service_lines?.forEach(line => {
        service_lines[mapTitleToIndex(line.title)] = { ...line };
      });

      visit_object.service_lines = service_lines.filter(Boolean);

      const isAdhd = visit_object.service_lines.some(line => line.name === 'adhd');

      return {
        ...state,
        service_line: action.visit_object.service_line,
        visit_object,
        isAdhd
      };
    },
    [patient_action_types.SET_SCORE]: (state, action) => {
      const scores = { ...state.scores };
      scores[action.assessment_type][action.name] = action.value;
      return {
        ...state,
        scores
      };
    },
    [patient_action_types.SET_FOLLOWUP_VISIT]: (state, action) => {
      const visit_object = { ...state.visit_object };
      visit_object.is_follow_up = action.is_follow_up;

      return {
        ...state,
        visit_object
      };
    },
    [patient_action_types.SET_REACTIVATION_VISIT]: (state, action) => {
      const { reactivation_visit } = action;

      return {
        ...state,
        reactivation_visit
      };
    },
    [patient_action_types.SET_PENDING_PLAN_CHANGE]: (state, action) => {
      const { change } = action;

      let pendingPlanChange = {};
      if (!isEmpty(change)) {
        pendingPlanChange = { ...change };
      }

      return {
        ...state,
        pending_plan_change: pendingPlanChange
      };
    },
    [patient_action_types.SET_PLAN]: (state, action) => {
      return {
        ...state,
        plan: action.plan
      };
    },
    [patient_action_types.SET_PLAN_GROUP_PLAN]: (state, action) => {
      return {
        ...state,
        planGroupPlan: action.planGroupPlan
      };
    },

    [patient_action_types.SET_SELECTED_PLAN_PRICE]: (state, action) => {
      return {
        ...state,
        selectedPlanPrice: action.selectedPlanPrice
      };
    },

    [patient_action_types.SET_PATIENT_PLAN_PRICE]: (state, action) => {
      return {
        ...state,
        patientPlanPrice: action.patientPlanPrice
      };
    },

    [patient_action_types.SET_PLAN_PRICE_SUBSCRIPTION]: (state, action) => {
      return {
        ...state,
        planPriceSubscription: action.planPriceSubscription
      };
    },

    [patient_action_types.SET_CARE_MANAGER]: (state, action) => {
      const { care_manager } = action;

      if (isEmpty(care_manager)) {
        return {
          ...state,
          care_manager: {}
        };
      }
      return {
        ...state,
        care_manager
      };
    },
    [patient_action_types.SET_CARE_COORDINATOR]: (state, action) => {
      const { care_coordinator } = action;

      if (isEmpty(care_coordinator)) {
        return {
          ...state,
          care_coordinator: {}
        };
      }
      return {
        ...state,
        care_coordinator
      };
    },
    [patient_action_types.SET_THERAPIST]: (state, action) => {
      const { therapist } = action;

      if (isEmpty(therapist)) {
        return {
          ...state,
          therapist: {}
        };
      }

      return {
        ...state,
        therapist
      };
    },
    [patient_action_types.SET_PROVIDER]: (state, action) => {
      const { provider } = action;

      if (isEmpty(provider)) {
        return {
          ...state,
          provider: {}
        };
      }

      return {
        ...state,
        current_provider: provider
      };
    },
    [patient_action_types.REMOVE_PROVIDER]: state => {
      return {
        ...state,
        current_provider: {}
      };
    },
    [patient_action_types.SET_CARE_MANAGER_AVAILABILITY]: (state, action) => {
      return {
        ...state,
        is_care_manager_available: action.is_care_manager_available
      };
    },
    [patient_action_types.SET_TREATMENT_OBJECT]: (state, action) => {
      const treatments = [...action.treatment_object];
      if (treatments[treatments.length - 1] === 'Other') {
        treatments.pop();
      }

      return {
        ...state,
        treatment_object: treatments
      };
    },
    [patient_action_types.REMOVE_VISIT]: state => {
      return {
        ...state,
        service_line: null,
        visit_object: null
      };
    },

    [patient_action_types.SET_TREATMENT]: (state, action) => {
      return {
        ...state,
        treatment_object: action.treatment_object
      };
    },
    [patient_action_types.SET_DOSAGE]: (state, action) => {
      return {
        ...state,
        dosage_object: action.dosage_object
      };
    },
    [patient_action_types.SET_STATE_WITH_STEP]: (state, action) => {
      return {
        ...state,
        step: action.new_step,
        patient_state: action.new_state
      };
    },
    [patient_action_types.SET_PATIENT_QUESTIONS]: (state, action) => {
      return {
        ...state,
        step: action.q_id,
        question_bank_id: action.bank_id,
        current_bank_name: action.bank_name,
        questions: action.questions,
        total_step: action.total_step,
        question_banks_step: action.bank_step
      };
    },
    [SET_QUESTION_BANKS]: (state, action) => {
      const question_banks = [...action.question_banks];

      return {
        ...state,
        question_banks,
        question_banks_step: action.question_banks_step,
        question_bank_objects: action.question_bank_objects
      };
    },
    [patient_action_types.SET_QUESTION_BANKS_STEP]: (state, action) => {
      return {
        ...state,
        question_banks_step: action.question_banks_step
      };
    },
    [patient_action_types.SET_SERVICE_LINE]: (state, action) => {
      return {
        ...state,
        service_line: action.service_line
      };
    },
    [patient_action_types.REMOVE_PATIENT_QUESTIONS]: state => {
      return {
        ...state,
        questions: null,
        total_step: 1,
        step: 0
      };
    },
    [patient_action_types.REMOVE_PATIENT_QUESTION_BANKS]: state => {
      return {
        ...state,
        question_banks: []
      };
    },
    [patient_action_types.SET_PATIENT_TASKS]: (state, action) => {
      const tasks = [...action.tasks];
      return {
        ...state,
        outstanding_tasks: tasks
      };
    },
    [patient_action_types.UPDATE_TASK]: (state, action) => {
      const outstanding_tasks = state.outstanding_tasks.filter(task => task.id !== action.task.id);

      return {
        ...state,
        outstanding_tasks
      };
    },
    [patient_action_types.SET_VISITS_FOR_PATIENT]: (state, action) => {
      return {
        ...state,
        visits: action.visits
      };
    },
    [patient_action_types.SET_GLOBAL_PROGRESSBAR_WIDTH]: (state, action) => {
      return {
        ...state,
        global_progressbar_width: action.width
      };
    },
    [patient_action_types.SET_AVAILABLE_DATES]: (state, action) => {
      let { dates } = action;

      if (!dates) {
        dates = [];
      }

      return {
        ...state,
        availableDates: dates
      };
    },
    [patient_action_types.GET_AVAILABLE_DATES_FETCH]: state => {
      return {
        ...state,
        availableDatesLoading: true
      };
    },
    [patient_action_types.GET_AVAILABLE_DATES_SUCCESS]: state => {
      return {
        ...state,
        availableDatesLoading: false
      };
    },
    [patient_action_types.GET_AVAILABLE_DATES_ERROR]: state => {
      return {
        ...state,
        availableDatesLoading: false
      };
    },
    [patient_action_types.SET_AVAILABLE_TIME_SLOTS]: (state, action) => {
      return {
        ...state,
        availableTimeSlots: action.timeSlots
      };
    },
    [patient_action_types.SET_PATIENTS_APPOINTMENT]: (state, action) => {
      const { appointment } = action;
      if (!appointment) {
        return state;
      }

      return {
        ...state,
        appointments: [...state.appointments, appointment]
      };
    },
    [patient_action_types.REMOVE_APPOINTMENT_BY_ID]: (state, action) => {
      const oldAppointments = new Array(...state.appointments);
      const index = oldAppointments.findIndex(item => item.id === action.id);
      const appointments = oldAppointments.splice(index, 1);
      return {
        ...state,
        appointments
      };
    },
    [patient_action_types.SET_TIME_SLOT]: (state, action) => {
      return {
        ...state,
        selectedTimeSlot: action.timeSlot
      };
    },
    [patient_action_types.GET_PRESCRIPTIONS_FETCH]: state => {
      return {
        ...state,
        prescriptionsLoading: true
      };
    },
    [patient_action_types.GET_PRESCRIPTIONS_SUCCESS]: state => {
      return {
        ...state,
        prescriptionsLoading: false
      };
    },
    [patient_action_types.GET_PRESCRIPTIONS_ERROR]: state => {
      return {
        ...state,
        prescriptionsLoading: false
      };
    },
    [patient_action_types.SET_PRESCRIPTIONS]: (state, action) => {
      return {
        ...state,
        // prescriptions: action.page === 1 ? action.prescriptions : [...state.prescriptions, ...action.prescriptions]
        prescriptions: [...action.prescriptions]
      };
    },
    // cancel sunscription
    [patient_action_types.CANCEL_SUBSCRIPTION_START]: (state, action) => {
      return {
        ...state,
        cancelSubscription: {
          loading: true,
          redirecting: true,
          textOnLoading: action.textOnLoading
        }
      };
    },
    [patient_action_types.CANCEL_SUBSCRIPTION_SUCESS]: (state, action) => {
      return {
        ...state,
        cancelSubscription: {
          loading: false,
          redirecting: true,
          textOnLoaded: action.textOnLoaded,
          descriptionOnLoaded: action.descriptionOnLoaded
        }
      };
    },
    [patient_action_types.CANCEL_SUBSCRIPTION_FINISHED]: state => {
      return {
        ...state,
        outstanding_tasks: [],
        appointments: [],
        cancelSubscription: {
          loading: false,
          redirecting: false
        }
      };
    },
    // coupons
    [patient_action_types.INIT_CANCELLATION_FLOW]: (state, action) => {
      return {
        ...state,
        cancellation_discount_end_date: action.discountEndDate,
        applyCoupon: {
          loading: false,
          redirecting: false
        },
        cancelSubscription: {
          loading: false,
          redirecting: false
        }
      };
    },
    [patient_action_types.APPLY_COUPON_START]: (state, action) => {
      return {
        ...state,
        applyCoupon: {
          loading: true,
          redirecting: true,
          textOnLoading: action.textOnLoading
        }
      };
    },
    [patient_action_types.APPLY_COUPON_SUCESS]: (state, action) => {
      return {
        ...state,
        cancellation_discount_end_date: action.discountEndDate,
        applyCoupon: {
          loading: false,
          redirecting: true,
          textOnLoaded: action.textOnLoaded,
          descriptionOnLoaded: action.descriptionOnLoaded
        }
      };
    },
    [patient_action_types.APPLY_COUPON_FINISHED]: state => {
      return {
        ...state,
        applyCoupon: {
          loading: false,
          redirecting: false
        }
      };
    },
    [patient_action_types.SET_NEXT_RATING]: (state, action) => {
      return {
        ...state,
        nextRating: {
          ratingType: action.ratingType,
          userId: action.userId,
          appointmentStartAt: action.appointmentStartAt,
          carePersonFullName: action.carePersonFullName,
          carePersonId: action.carePersonId,
          carePersonProfilePhotoUrl: action.carePersonProfilePhotoUrl
        }
      };
    },
    [patient_action_types.SET_DIRECT_MESSAGING_PILOT]: (state, action) => {
      return {
        ...state,
        direct_messaging_pilot: action.dmFlag
      };
    },
    [patient_action_types.SET_REGION]: (state, action) => {
      return {
        ...state,
        region: action.region
      };
    },
    [patient_action_types.SET_OFFERING_KEY]: (state, action) => {
      return {
        ...state,
        offering_key: action.offering_key
      };
    },
    [patient_action_types.SET_ELIGIBLE_SERVICE_LINES]: (state, action) => {
      return {
        ...state,
        eligibleServiceLines: action.serviceLines
      };
    },
    [patient_action_types.SET_ACCOUNT_ID]: (state, action) => {
      return {
        ...state,
        accountId: action.accountId
      };
    },
    [patient_action_types.SET_SERVICE_LINE_NOT_AVAILABLE]: (state, action) => {
      return {
        ...state,
        service_line_not_available: action.service_line_not_available
      };
    },
    [patient_action_types.SET_IS_ONLINE_CHECKIN]: (state, action) => {
      return {
        ...state,
        isOnlineCheckin: action.isOnlineCheckin
      };
    },
    [patient_action_types.SET_INITIAL_SERVICE_LINE]: (state, action) => {
      return {
        ...state,
        initialServiceLine: action.initialServiceLine
      };
    },
    [patient_action_types.SET_UPGRADABLE_PLANS]: (state, action) => {
      return {
        ...state,
        upgradablePlans: action.upgradablePlans
      };
    },
    [patient_action_types.SET_MEDITATION_UPGRADE]: (state, action) => {
      return {
        ...state,
        isMeditationUpgrade: action.isMeditationUpgrade
      };
    },
    [patient_action_types.SET_INITIAL_PLAN]: (state, action) => {
      return {
        ...state,
        initialPlan: action.initialPlan
      };
    },
    [patient_action_types.SET_CHARGE_ONE_TIME]: (state, action) => {
      return {
        ...state,
        isChargeOneTime: action.isChargeOneTime
      };
    },
    [patient_action_types.SET_CHANGING_TRIAL_PLAN]: (state, action) => {
      return {
        ...state,
        isChangingTrialPlan: action.isChangingTrialPlan
      };
    },
    [patient_action_types.SET_IS_MEDICATION_SHIPPED]: (state, action) => {
      return {
        ...state,
        isMedicationShipped: action.isMedicationShipped
      };
    },
    [patient_action_types.SET_MEDICATION_WAITLIST]: (state, action) => {
      return {
        ...state,
        medication_waitlist: action.medicationWaitlist
      };
    },
    [patient_action_types.SET_ENTITLEMENTS]: (state, action) => {
      return {
        ...state,
        entitlements: action.entitlements
      };
    },
    [patient_action_types.SET_POST_CHECKOUT_FLOW]: (state, action) => {
      return {
        ...state,
        postCheckOutFlowVariation: action.value
      };
    },
    [patient_action_types.SET_COMPARE_PLAN_OFFERING_KEY]: (state, action) => {
      return {
        ...state,
        compare_plan_offering_key: action.offeringKey
      };
    },
    [patient_action_types.SET_PAYMENT_SUBSCRIPTION]: (state, action) => {
      return {
        ...state,
        paymentSubscription: action.value
      };
    },
    [patient_action_types.SET_CANCELLATION_APPOINTMENTS]: (state, action) => {
      const { cancellationAppointments } = action;

      return {
        ...state,
        cancellationAppointments
      };
    },
    [patient_action_types.SET_CANCEL_NOTIFICATION_READ]: (state, { id, value }) => {
      const newAppts = state.cancellationAppointments.map(el =>
        el.id === id ? { ...el, is_cancel_notification_read: value } : el
      );

      return {
        ...state,
        cancellationAppointments: newAppts
      };
    },
    [patient_action_types.SET_ILV_STATUS]: (state, action) => {
      return {
        ...state,
        ilvStatus: action.ilvStatus
      };
    },
    [patient_action_types.SET_ILV_PRESCRIBER_INFO]: (state, action) => {
      return {
        ...state,
        ilvPrescriber: action.ilvPrescriber
      };
    },
    [patient_action_types.SET_SUBSCRIBED_AFTER_REMOVING_CARE_COUNSELOR]: (state, action) => {
      return {
        ...state,
        subscribedAfterRemovingCareCounselor: action.subscribedAfterRemovingCareCounselor
      };
    },
    [patient_action_types.SET_REASSIGNMENT_REASON]: (state, action) => {
      return {
        ...state,
        reassignmentReason: action.reason
      };
    },
    [patient_action_types.SET_CAN_REACTIVATE]: (state, action) => {
      return {
        ...state,
        canReactivate: action.value
      };
    },
    [patient_action_types.SET_IS_REACTIVATING]: (state, action) => {
      return {
        ...state,
        is_reactivating: action.value
      };
    },
    [patient_action_types.SET_PREFERRED_NAME]: (state, action) => {
      return {
        ...state,
        preferred_name: action.value
      };
    },
    [patient_action_types.SET_CANCELLATION_DATE]: (state, action) => {
      return {
        ...state,
        cancellationDate: action.value
      };
    },
    [patient_action_types.SET_HIPAA_AUTHORIZATION_STATUS]: (state, action) => {
      return {
        ...state,
        hipaaAuthorizationStatus: action.hipaaAuthorizationStatus
      };
    },
    [patient_action_types.SET_PREFERRED_PLAN]: (state, action) => {
      return {
        ...state,
        preferredPlan: action.preferredPlan
      };
    },
    [patient_action_types.SET_IMPACT_TOKEN]: (state, action) => {
      return {
        ...state,
        impactToken: action.value
      };
    },
    [patient_action_types.SET_SHOW_UPSELL]: (state, action) => {
      return {
        ...state,
        shouldShowUpsell: action.value
      };
    },
    [patient_action_types.SET_SHOW_RTE_MODAL]: (state, action) => {
      return {
        ...state,
        showRteModal: action.showRteModal
      };
    }
  },
  init_patient_state
);
